import React, { useState, useEffect, useRef } from "react";
import "./InvoiceForm.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Magnifier from "./Magnifying";
import axios from "axios";
import { Modal, Progress, notification } from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faRightLong } from "@fortawesome/free-solid-svg-icons";
import uploadImage from "../assets/upload_img.png";
import { InfoCircleOutlined } from "@ant-design/icons";
import HeaderNew from "../shared/Header";

const InvoiceForm = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState({});
  const [hoveredBoundingBox, setHoveredBoundingBox] = useState([]);
  const [fileData, setFileData] = useState(null);
  const navigate = useNavigate();
  const [s3url, setS3Url] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editParsedGSTIN, setEditParsedGSTIN] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const [image, setImage] = useState(null);
  const [isImageReady, setIsImageReady] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const [uploadFailed, setUploadFailed] = useState(false);

  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const [bookingData, setBookingData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [fieldValidity, setFieldValidity] = useState({
    Customer_Name: null,
    Organization_Gstin: null,
    Organization_Name: null,
    Hotel_Gstin: null,
  });

  const [values, setValues] = useState({
    Customer_Name: "",
    Organization_Gstin: "",
    Organization_Name: "",
    Hotel_Gstin: "",
    Invoice_Number: "",
    Invoice_Date: "",
    Total_Amount: "",
    Total_Tax_Amount: "",
  });

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (parsedData?.final_data) {
      setProgress(100);
      setIsModalVisible(false);
      handleAutofillData();
      clearInterval(progressInterval);
    }
  }, [parsedData]);

  useEffect(() => {
    if (!isEdit) {
      setIsModalVisible(true);
    }
  }, [isEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };
      updateFieldValidity(name, value ?? ""); // Pass an empty string if value is null/undefined
      return newValues;
    });
  };

  const updateFieldValidity = (fieldName, fieldValue) => {
    const bookingDetails = {
      Customer_Name: location.state?.client_name || "",
      Organization_Gstin: location.state?.client_gstin || "",
      Organization_Name: location.state?.hotel_name || "",
      Hotel_Gstin: location.state?.hotel_gstin || "",
    };

    if (typeof bookingDetails[fieldName] !== "undefined") {
      const bookingValue = bookingDetails[fieldName] || ""; // Ensure it's at least an empty string
      setFieldValidity((prevState) => ({
        ...prevState,
        [fieldName]:
          (fieldValue || "").trim().toLowerCase() ===
            bookingValue.trim().toLowerCase()
            ? "match"
            : "mismatch",
      }));
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      setFileData(file);
      setUploadFailed(false);
      try {
        setUploading(true);
        setProgress(5);

        const interval = setInterval(() => {
          setProgress((prevProgress) => {
            const nextProgress = prevProgress + 2;
            return nextProgress < 100 ? nextProgress : prevProgress;
          });
        }, 1000);

        setProgressInterval(interval);

        setError(null);
        const response = await fetch(
          "https://dev-backend.finkraftai.com/api/parseimage",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.status === 500)
          throw new Error("Upload failed with status code 500");

        if (!response.ok) throw new Error("Failed to fetch image");

        const imageData = await response.json();
        setImage(imageData.image);
        setIsImageReady(true);
        await parseData(imageData);
      } catch (error) {
        setError("An error occurred during file processing.");
        console.error("Error processing file: ", error);
        clearInterval(progressInterval);
        setProgress(0);
        setUploadFailed(true);
      } finally {
        setUploading(false);
      }
    }
  };

  const base64ToFile = (base64, filename) => {
    try {
      // Check if the base64 string is in the correct format
      if (!base64.includes(",")) {
        throw new Error("Invalid base64 format");
      }

      const arr = base64.split(",");
      const mimeMatch = arr[0].match(/:(.*?);/);

      // Validate MIME type extraction
      if (!mimeMatch || mimeMatch.length < 2) {
        throw new Error("Invalid MIME type");
      }

      const mime = mimeMatch[1]; // Extract MIME type
      const bstr = atob(arr[1]); // Decode base64
      const n = bstr.length;
      const u8arr = new Uint8Array(n);

      // Fill Uint8Array with decoded data
      for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }

      // Return a File object
      return new File([u8arr], filename, { type: mime });
    } catch (error) {
      console.error("Failed to convert base64 to file:", error);
      return null; // Or you can return `null` to signify failure
    }
  };

  const convertToBase64Format = (base64Data, mimeType) => {
    return `data:${mimeType};base64,${base64Data}`;
  };

  const parseData = async (image) => {
    try {
      let base64Data = image["image"];

      // If mimeType is known, for example, 'image/jpeg'
      const mimeType = "image/jpeg";

      // Convert to complete base64 format
      const formattedBase64 = convertToBase64Format(base64Data, mimeType);
      console.log("aergbdbvd", typeof formattedBase64);
      const fileBlob = base64ToFile(formattedBase64, "image.jpg"); // Change the filename as needed
      console.log("successs");

      // Create a FormData object
      console.log("dfb", fileBlob);
      const formData = new FormData();
      formData.append("file", fileBlob, "image.jpg");
      const parseResponse = await fetch(
        "https://dev-backend.finkraftai.com/api/parsedata",
        {
          method: "POST",
          body: formData,
        }
      );

      if (parseResponse.status === 500)
        throw new Error("Parsing failed with status code 500");

      if (!parseResponse.ok) throw new Error("Failed to parse data");

      const data = await parseResponse.json();
      setParsedData(data);
      setEditParsedGSTIN(data.final_data?.Organization_Gstin?.value || "");
    } catch (error) {
      console.error("Error parsing data: ", error);
      showUploadError();
      setParsedData(null);
      clearInterval(progressInterval);
      setProgress(0);
      setUploadFailed(true);
    }
  };

  const showUploadError = () => {
    setFileData(null);
    setImage(null);
    setIsImageReady(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleAutofillData = () => {
    if (parsedData?.final_data) {
      const valueData = {};
      const boundingBoxData = {};
      Object.entries(parsedData.final_data).forEach(
        ([key, { value, bounding_box, bounding_boxes }]) => {
          valueData[key] = value;
          boundingBoxData[key] =
            bounding_boxes || (bounding_box ? [bounding_box] : []);
          updateFieldValidity(key, value);
        }
      );
      setValues(valueData);
      setBoundingBoxes(boundingBoxData);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  const handleConfirmInvoice = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const emptyFields = Object.keys(values).filter((key) => !values[key]);

    if (emptyFields.length > 0) {
      notification.error({
        message: "Incomplete Data",
        description:
          "Please fill all the fields in the Invoice Data before confirming.",
        duration: 3, // Duration for the notification to display, can be adjusted
      });
      return; // Stop further processing if there are empty fields
    }

    // Existing mismatch check (optional based on requirement)
    const bookingDetails = {
      Customer_Name: location.state?.client_name,
      Organization_Gstin: location.state?.client_gstin,
      Organization_Name: location.state?.hotel_name,
      Hotel_Gstin: location.state?.hotel_gstin,
    };

    const firstFourKeys = [
      "Customer_Name",
      "Organization_Gstin",
      "Organization_Name",
      "Hotel_Gstin",
    ];
    const mismatched = firstFourKeys.filter(
      (key) => values[key] !== bookingDetails[key]
    );

    if (mismatched.length > 0) {
      notification.warning({
        message: "Data Mismatch",
        description: `The following data does not match booking records and will still be submitted: ${mismatched.join(
          ", "
        )}`,
      });
    }

    setIsProcessing(true);

    try {
      let fileUrl = s3url;
      if (!isEdit && fileData) {
        const formData = new FormData();
        formData.append("file", fileData);
        const uploadResponse = await axios.post(
          "https://dev-backend.finkraftai.com/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (uploadResponse.status === 200) {
          fileUrl = uploadResponse.data.s3_url;
          setS3Url(fileUrl);
        } else {
          console.error("Error uploading file:", uploadResponse.data.error);
          return;
        }
      }

      const updatePayload = {
        BookingDetails: location.state?._id,
        ParsedData: [
          { finalData: values },
          { boundingBoxes: boundingBoxes },
          { s3url: fileUrl },
        ],
      };

      const updateResponse = await axios.post(
        "https://dev-backend.finkraftai.com/api/bookings/update",
        updatePayload
      );
      if (updateResponse.status === 200) {
        navigate(`/bookings/${location.state?.hotel_id}`);
      } else {
        console.error("Error updating booking:", updateResponse.data.error);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isEdit && bookingData && bookingData.parseddata.length > 0) {
      const finalData = bookingData.parseddata[0].finalData || {};
      setValues({
        Invoice_Number: finalData.Invoice_Number || "",
        Invoice_Date: finalData.Invoice_Date || "",
        Hotel_Gstin: finalData.Hotel_Gstin || "",
        Total_Amount: finalData.Total_Amount || "",
        Total_Tax_Amount: finalData.Total_Tax_Amount || "",
        Customer_Name: finalData.Customer_Name || "",
        Organization_Gstin: finalData.Organization_Gstin || "",
        Organization_Name: finalData.Organization_Name || "",
        Hotel_Gstin: finalData.Hotel_Gstin || "", // Fix duplication
      });
    }
  }, [bookingData, isEdit]);

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const response = await axios.post(
          "https://dev-backend.finkraftai.com/api/bookings/fetch",
          {
            BookingDetails: location.state?._id,
          }
        );

        if (response.status === 200) {
          const bookingDetails = response.data.bookingDetails;
          setBookingData(bookingDetails);
          setIsImageReady(true);
          setImage(response.data.encodedImage);

          const existingS3Url =
            bookingDetails.parseddata.find((data) => data.s3url)?.s3url || "";
          setS3Url(existingS3Url);

          const boundingBoxData =
            bookingDetails.parseddata.find((data) => data.boundingBoxes) || {};
          setBoundingBoxes(boundingBoxData.boundingBoxes || {});

          const finalData =
            bookingDetails.parseddata.find((data) => data.finalData)
              ?.finalData || {};

          setValues({
            Invoice_Number: finalData.Invoice_Number || "",
            Invoice_Date: finalData.Invoice_Date || "",
            Hotel_Gstin: finalData.Hotel_Gstin || "",
            Total_Amount: finalData.Total_Amount || "",
            Total_Tax_Amount: finalData.Total_Tax_Amount || "",
            Customer_Name: finalData.Customer_Name || "",
            Organization_Gstin: finalData.Organization_Gstin || "",
            Organization_Name: finalData.Organization_Name || "",
          });
          setEditParsedGSTIN(finalData.Organization_Gstin || "");
        } else {
          setError(`Error: ${response.data.error}`);
        }
      } catch (err) {
        setError(`Error: ${err.message}`);
      }
    };
    if (isEdit) {
      fetchBookingData();
    }
  }, [isEdit]);

  const handleCopyClick = (field) => {
    const mapping = {
      Customer_Name: "client_name",
      Organization_Gstin: "client_gstin",
      Organization_Name: "hotel_name",
      Hotel_Gstin: "hotel_gstin",
    };

    const bookingField = mapping[field];

    if (bookingField && location.state[bookingField] !== undefined) {
      const newValues = { ...values, [field]: location.state[bookingField] };
      setValues(newValues);
      updateFieldValidity(field, location.state[bookingField]);
    }
  };

  const formatDate = (isoDateString) => {
    return isoDateString ? isoDateString.split("T")[0] : "";
  };

  const columns = {
    Customer_Name: "Customer Name",
    Organization_Gstin: "Organization GSTIN",
    Organization_Name: "Organization Name",
    Hotel_Gstin: "Hotel GSTIN",
    Invoice_Number: "Invoice Number",
    Invoice_Date: "Invoice Date",
    Total_Amount: "Total Amount",
    Total_Tax_Amount: "Total Tax Amount",
  };

  const handleColumnHover = (columnName) => {
    setHoveredBoundingBox(boundingBoxes[columnName] || []);
  };

  const handleColumnLeave = () => {
    setHoveredBoundingBox([]);
  };

  return (
    <div>
      <div className="HeaderContainer">
        <HeaderNew />
      </div>
      <div
        className={`invoiceform-container ${isModalVisible && !isEdit ? "blurred" : ""
          }`}
      >
        <Modal
          title="Upload Invoice"
          visible={isModalVisible}
          footer={uploadFailed ? (
            <div style={{ textAlign: "center", animation:'popUpFromCenter 0.3s ease-out', transformOrigin:'center'}}>
              <span style={{ color: "red" }}>UPLOAD FAILED - </span>
              <span
                onClick={() =>
                  fileInputRef.current && fileInputRef.current.click()
                }
                style={{
                  marginLeft: 10,
                  color: "#007CC2",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                RETRY
              </span>
            </div>
          ) : (
            <div>
              <Progress
                percent={progress}
                style={{ visibility: uploading ? "visible" : "hidden" }}
                showInfo={false}
              />
              {uploading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  <span>Processing...</span>
                  <span>{`${progress}%`}</span>
                </div>
              )}
            </div>
          )}
          onCancel={() => navigate(-1)} // Navigate to the previous page when close
          destroyOnClose
          width={400}
          className="custom-modal"
        >
          <div
            className="invoiceform"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "20px",
              boxSizing: "border-box",
              cursor: "pointer" ,
            }}
            onClick={() =>
              fileInputRef.current && fileInputRef.current.click()
            }
          >
            <img
              src={uploadImage}
              alt="Upload"
              style={{ marginTop: "15px", width: "20%", height: "auto" }}
            />

            <div
              className="upload-icon"
              style={{
                fontSize: "32px",
                color: "#191D23",
                marginTop: "10px",
              }}
            >
              <CloudUploadOutlined />
            </div>

            <p
              style={{
                marginTop: "10px",
                color: "#333",
                textAlign: "center",
                maxWidth: "100%",
              }}
            >
              Browse and choose the files you want to upload from your computer
            </p>

            <input
              id="fileUpload"
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
        </Modal>
        <div className="invoiceform-main-grid">
          <div className="invoiceform-left-section">
            <p className="invoiceform-booking-status">
              <strong>Booking Details:</strong>
              <span className="invoiceform-status-pending">Pending</span>
            </p>
            <div
              className="invoiceform-details-grid"
              style={{ display: "flex", height: "250px", overflowY: "scroll" }}
            >
              <div style={{ marginRight: "10px", flex: 1 }}>
                <div className="invoiceform-detail-box">
                  <h6 className="invoiceform-head">Booking Details</h6>
                  {[
                    {
                      label: "Customer Name",
                      value: location.state?.client_name,
                      field: "Customer_Name",
                    },
                    {
                      label: "Organization GSTIN",
                      value: location.state?.client_gstin,
                      field: "Organization_Gstin",
                    },
                    {
                      label: "Organization Name",
                      value: location.state?.hotel_name,
                      field: "Organization_Name",
                    },
                    {
                      label: "Hotel GSTIN",
                      value: location.state?.hotel_gstin,
                      field: "Hotel_Gstin",
                    },
                  ].map((item) => (
                    <div
                      className="invoiceform-row-details"
                      key={item.field}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <span style={{ width: "150px", fontWeight: "bold" }}>
                        {item.label}:
                      </span>
                      <span style={{ flex: 1 }}>{item.value}</span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleCopyClick(item.field)}
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ color: "#9c9c9c", marginRight: "4px" }}
                        />
                        <FontAwesomeIcon
                          icon={faRightLong}
                          style={{ color: "#9c9c9c" }}
                        />
                      </span>
                    </div>
                  ))}
                </div>

                <div className="invoiceform-detail-box">
                  <h6 className="invoiceform-head">
                    Additional Booking Details
                  </h6>
                  {[
                    {
                      label: "Hotel_Address",
                      value: location.state?.hotel_address,
                    },
                    {
                      label: "Check-out Date",
                      value: formatDate(location.state?.check_out_date),
                    },
                    { label: "Total_GST", value: location.state?.total_gst },
                    { label: "Invoice No", value: location.state?.invoice_no },
                  ].map((item, index) => (
                    <div
                      className="invoiceform-row-details"
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <span style={{ width: "150px", fontWeight: "bold" }}>
                        {item.label}:
                      </span>
                      <span style={{ flex: 1 }}>{item.value}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ flex: 1 }}>
                <div className="invoiceform-detail-box">
                  <div className="invoiceform-detail-header">
                    <h6 className="invoiceform-head">Invoice Details</h6>
                  </div>
                  <table className="invoiceform-details-table">
                    <tbody>
                      {Object.keys(columns).map((key) => (
                        <tr
                          key={key}
                          className="invoiceform-row-details1"
                          onMouseEnter={() => handleColumnHover(key)}
                          onMouseLeave={handleColumnLeave}
                        >
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 16px",
                            }}
                          >
                            <label
                              style={{ width: "150px", fontWeight: "bold" }}
                            >
                              {columns[key]}:
                            </label>
                          </td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                              padding: "8px 16px",
                              width: "100%",
                            }}
                          >
                            <input
                              className="invoiceform-input"
                              placeholder={`Enter ${columns[key]}`}
                              name={key}
                              value={values[key] || ""}
                              onChange={handleInputChange}
                              style={{
                                borderColor:
                                  fieldValidity[key] === "match"
                                    ? "green"
                                    : fieldValidity[key] === "mismatch"
                                      ? "red"
                                      : "inherit",
                                outline: "none",
                                paddingRight: "30px",
                                flex: 1,
                                position: "relative",
                              }}
                            />
                            {fieldValidity[key] === "match" && (
                              <CheckOutlined
                                style={{
                                  color: "green",
                                  position: "absolute",
                                  right: "20px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            {fieldValidity[key] === "mismatch" && (
                              <CloseOutlined
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  right: "20px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="additional-details">
              <p className="additional-text">
                <InfoCircleOutlined
                  style={{ color: "#007CC2", marginRight: "5px" }}
                />
                Additional details:
                <br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <button className="register-button">REGISTER NOW</button>
            </div>
            <hr />
            <div className="invoiceform-total">
              <span>Grand Total: ₹{values.Total_Amount || "0.00"}</span>
              <div style={{ display: "flex", gap: "5px" }}>
                <div>
                  <button
                    className="invoiceform-btn-cancel"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className="invoiceform-btn-confirm"
                    onClick={handleConfirmInvoice}
                    disabled={isProcessing}
                    style={{
                      backgroundColor: isProcessing ? "#d3d3d3" : "#007CC2",
                      color: isProcessing ? "#fff" : "white",
                    }}
                  >
                    {isProcessing
                      ? "Processing..."
                      : isEdit
                        ? "Update Data"
                        : "Confirm Invoice"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="invoiceform-right-section">
            <div
              className="invoiceform-upload-box"
              onClick={() => document.getElementById("fileUpload").click()}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDragOver(e)}
              onDragLeave={(e) => handleDragLeave(e)}
            >
              <div>
                {uploading ? (
                  <div className="loading-spinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ margin: "auto", display: "block" }}
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        fill="none"
                        stroke="#007CC2"
                        strokeWidth="10"
                        r="35"
                        strokeDasharray="164.93361431346415 56.97787143782138"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          keyTimes="0;1"
                          values="0 50 50;360 50 50"
                        ></animateTransform>
                      </circle>
                    </svg>
                    <p>Processing your invoice...</p>
                  </div>
                ) : isImageReady ? (
                  <Magnifier
                    base64Image={image}
                    scale={1}
                    magnifierHeight={150}
                    magnifierWidth={150}
                    boundingBoxes={{ hovered: hoveredBoundingBox }}
                  />
                ) : (
                  <div className="invoice-msg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ cursor: "pointer" }}
                    >
                      <path
                        d="M21.375 13.5V19.5C21.375 19.7984 21.2565 20.0845 21.0455 20.2955C20.8345 20.5065 20.5484 20.625 20.25 20.625H3.75C3.45163 20.625 3.16548 20.5065 2.9545 20.2955C2.74353 20.0845 2.625 19.7984 2.625 19.5V13.5C2.625 13.2017 2.74353 12.9155 2.9545 12.7045C3.16548 12.4936 3.45163 12.375 3.75 12.375C4.04837 12.375 4.33452 12.4936 4.5455 12.7045C4.75647 12.9155 4.875 13.2017 4.875 13.5V18.375H19.125V13.5C19.125 13.2017 19.2435 12.9155 19.4545 12.7045C19.6655 12.4936 19.9516 12.375 20.25 12.375C20.5484 12.375 20.8345 12.4936 21.0455 12.7045C21.2565 12.9155 21.375 13.2017 21.375 13.5ZM9.04594 7.54597L10.875 5.71878V13.5C10.875 13.7984 10.9935 14.0845 11.2045 14.2955C11.4155 14.5065 11.7016 14.625 12 14.625C12.2984 14.625 12.5845 14.5065 12.7955 14.2955C13.0065 14.0845 13.125 13.7984 13.125 13.5V5.71878L14.9541 7.54878C15.0587 7.65343 15.1829 7.73644 15.3197 7.79307C15.4564 7.84971 15.6029 7.87886 15.7509 7.87886C15.8989 7.87886 16.0455 7.84971 16.1822 7.79307C16.3189 7.73644 16.4432 7.65343 16.5478 7.54878C16.6525 7.44414 16.7355 7.3199 16.7921 7.18317C16.8487 7.04645 16.8779 6.8999 16.8779 6.75191C16.8779 6.60391 16.8487 6.45737 16.7921 6.32064C16.7355 6.18391 16.6525 6.05968 16.5478 5.95503L12.7978 2.20503C12.6933 2.10015 12.5691 2.01694 12.4324 1.96016C12.2956 1.90337 12.149 1.87415 12.0009 1.87415C11.8529 1.87415 11.7063 1.90337 11.5695 1.96016C11.4328 2.01694 11.3086 2.10015 11.2041 2.20503L7.45406 5.95503C7.34942 6.05968 7.2664 6.18391 7.20977 6.32064C7.15314 6.45737 7.12399 6.60391 7.12399 6.75191C7.12399 7.05079 7.24272 7.33744 7.45406 7.54878C7.66541 7.76013 7.95205 7.87886 8.25094 7.87886C8.54982 7.87886 8.83647 7.76013 9.04781 7.54878L9.04594 7.54597Z"
                        fill="#007CC2"
                      />
                    </svg>
                    <p>
                      Drag 'n' drop your hotel invoice here,
                      <br />
                      or click to select hotel invoice file
                    </p>
                  </div>
                )}
              </div>
              <input
                id="fileUpload"
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForm;
