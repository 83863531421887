import { Button, Checkbox, Input, message, Select, Modal } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT_HOTEL_INFO_REGISTER } from "../../landing.page.constant";
export default function HotelInfoForm(props) {
  const [hotelName, setHotelName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [gstin, setGSTIN] = useState("");
  const [allStates, setAllState] = useState([]);
  const [allCities, setAllCity] = useState([]);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [showSwitchOption, setShowSwitchOption] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tradeName, setTradeName] = useState("");
  const [proceedWithOriginalName, setProceedWithOriginalName] = useState(false);

  const BEARER_TOKEN = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5qRkVSVEF4TVVVM1FVVTVRalZHUWpZNFJqWTVPRVUxUWprNU9UazVOamd4UXpRM1FrWkVSUSJ9.eyJodHRwczovL3Nob3BlbGVjdC5nc3RpbnB1dC5jb20vdXNlcl9tZXRhZGF0YSI6eyJhcHBfdXNlcl9pZCI6IjZhYjZhNWU4LWU3N2YtNGY0NS05ZWYxLTdiNmNhZDkzYzc2ZCIsImNsdXN0ZXJfaWQiOiI0ODBjYjJmYy1jYmU5LTRjOTctOTI4Ni0xMmI2NGFiNzRhYTAifSwiaHR0cHM6Ly9zaG9wZWxlY3QuZ3N0aW5wdXQuY29tL2FwcF9tZXRhZGF0YSI6eyJhdXRob3JpemF0aW9uIjp7Imdyb3VwcyI6W10sInJvbGVzIjpbXSwicGVybWlzc2lvbnMiOltdfX0sImh0dHBzOi8vc2hvcGVsZWN0LmdzdGlucHV0LmNvbS9lbWFpbCI6Im1tdEBmaW5rcmFmdC5haSIsImFwcF9tZXRhZGF0YSI6eyJhdXRob3JpemF0aW9uIjp7Imdyb3VwcyI6W10sInJvbGVzIjpbXSwicGVybWlzc2lvbnMiOltdfX0sImlzcyI6Imh0dHBzOi8vc2hvcGVsZWN0LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2NjY5NjUzYTExODMzNWZhOWNhOWNkNjYiLCJhdWQiOlsiaHR0cHM6Ly90aXRhbi13ZWJhcGkuY29tIiwiaHR0cHM6Ly9zaG9wZWxlY3QuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcyOTc3NzgyNSwiZXhwIjoxNzMyMzY5ODIzLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZ3R5IjoicGFzc3dvcmQiLCJhenAiOiJ4c3J6bTA2RWZhekE0QjF4ZTFhZjNZdHhGR3poMzBSTyJ9.lgKmKFA0BQr1uWq40FEJKFKvgElNz3727rhfoSqmpZwfieZ2f0I_vo9DJUCMKxKJTrc1Fgo2taWda9_ObDsTVM-kmL9OvqtkLWEiG5qhcAD8OoEdEH4apwBJECU50DB3-bBvuQE1ewasE4R2IKIWQi2AmB1MtgiByaf5b4NFEREtcz0k9NwlRrx2bGtzwUOip6nkWvJEHlbfLo9wV0t5ftH5vYts9D6BZGQDAABz7khJcZLEvNWJEWYIrWBVELyf3N9O75hrR4Eho0y17zMGTfqK3MJCc2Dh4G7fZld8njQ9CpuZXGUTQlcU1jCMOL1TCGq2jSY6ZiDOmTO9n6D6Zw";

  useEffect(() => {
    fetchStateList();
  }, []);

  const fetchStateList = async () => {
    const response = await axios.post(
      "https://countriesnow.space/api/v0.1/countries/states",
      { country: "India" }
    );
    if (!response.error) {
      setAllState(response.data?.data?.states || []);
    }
  };

  const fetchCityList = async (stateName) => {
    const response = await axios.post(
      "https://countriesnow.space/api/v0.1/countries/state/cities",
      {
        country: "India",
        state: stateName,
      }
    );
    if (!response.error) {
      setAllCity(response.data?.data || []);
    }
  };

  const isGSTINValid = (gstin) => {
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/;
    return gstinRegex.test(gstin);
  };

  const fetchGSTINData = async (gstin) => {
    try {
      const response = await axios.post(
        "https://stage-apiplatform.finkraftai.com/api/gstmeta/gstinfo",
        { gstin: gstin },
        {
          headers: {
            Authorization: `Bearer ${BEARER_TOKEN}`,
          },
        }
      );
      if (response.data.status) {
        console.log(response.data);
        setTradeName(response.data.data.tradeNam) // Set trade name received
        return response.data.data.tradeNam; // Return trade name
      } else {
        throw new Error("Failed to fetch GSTIN data");
      }
    } catch (error) {
      messageApi.error("Error fetching GSTIN data");
      throw error;
    }
  };

  const handleRegisterHotel = async () => {
    if (!hotelName || !gstin || !acceptedTerms) {
      messageApi.error("Please provide important information");
      return;
    }
    if (!isGSTINValid(gstin)) {
      messageApi.error("GSTIN is not valid");
      return;
    }

    try {
      const fetchedTradeName = await fetchGSTINData(gstin);
      if (fetchedTradeName !== hotelName && !proceedWithOriginalName) {
        setShowModal(true); // Open modal
        return;
      }

      await registerHotel();
    } catch (error) {
      console.error(error);
      messageApi.error("An unexpected error occurred");
    }
  };

  const registerHotel = async () => {
    try {
      const payload = {
        hotel_name: hotelName,
        state: state,
        city: city,
        email: email,
        gstin: gstin,
      };

      const response = await axios.post(API_ENDPOINT_HOTEL_INFO_REGISTER, payload);
      if (response.status) {
        messageApi.success("🎉 Awesome, Hotel registered successfully!");
        props.onSuccess && props.onSuccess(response.data?.data);
      } else {
        messageApi.error("Something went wrong while registration");
      }
    } catch (error) {
      messageApi.error("An unexpected error occurred during registration");
    }
  };

  const switchToTradeName = () => {
    setHotelName(tradeName); // Switch to trade name
    setShowModal(false);
    messageApi.success("Hotel name switched to trade name successfully!");
  };

  const handleOkay = () => {
    setShowModal(false); // Close the modal
    setProceedWithOriginalName(true); // Allow proceeding with the original name
  };


  return (
    <div className="HotelInfoForm">
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>Name</small>
        <Input
          placeholder="Hotel Name"
          value={hotelName}
          onChange={(e) => setHotelName(e.target.value)}
        />
      </div>
      {showSwitchOption && (
        <div className="FormGroup" style={{ marginBottom: 18 }}>
          <small style={{ fontSize: 14, color: "black" }}>
            Suggested Trade Name
          </small>
          <Input
            placeholder="Trade Name"
            value={tradeName} // Display fetched trade name
            disabled
            style={{ color: "black" }}
          />
          <Button
            type="primary"
            onClick={switchToTradeName}
            style={{ marginTop: 8 }}
          >
            Switch
          </Button>
        </div>
      )}
      <div
        className="FormGroup"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 18,
        }}
      >
        <div className="FormGroupHalf" style={{ width: "49%" }}>
          <small style={{ fontSize: 14, color: "#555555" }}>State</small>
          <br />
          <Select
            showSearch
            placeholder="Select State"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%" }}
            options={allStates.map((item) => {
              return { value: item.name, label: item.name };
            })}
            onChange={(selectedState) => {
              setState(selectedState);
              fetchCityList(selectedState);
            }}
          />
        </div>
        <div className="FormGroupHalf" style={{ width: "49%" }}>
          <small style={{ fontSize: 14, color: "#555555" }}>City</small>
          <br />
          <Select
            showSearch
            placeholder="Select City"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(selectedCity) => setCity(selectedCity)}
            style={{ width: "100%" }}
            options={allCities.map((item) => {
              return { value: item, label: item };
            })}
            disabled={!state}
          />
        </div>
      </div>
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>Email</small>
        <Input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>
          GSTIN <b style={{ color: "#e74c3c" }}>*</b>
        </small>
        <Input placeholder="GSTIN" onChange={(e) => setGSTIN(e.target.value)} />
      </div>
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <Checkbox
          checked={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
        >
          By ticking this, you confirm that all the information provided is
          accurate and authentic to the best of your knowledge.
        </Checkbox>
      </div>
      <div style={{ marginTop: 12 }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={handleRegisterHotel}
        >
          Proceed
        </Button>
      </div>
      {contextHolder}
      <Modal
        visible={showModal}
        onCancel={handleOkay}
        footer={null}
        centered // Center the modal on the page
        bodyStyle={{ textAlign: 'center', padding: '15px' }} // Add padding to the content
      >
        <div style={{ color: '#007CC2', fontFamily: 'Noto Sans', fontSize: '36px', fontWeight: 700, lineHeight: '31px', textAlign: 'center', marginBottom: '25px', marginTop: '25px' }}>
          Please verify your details
        </div>
        <p>
          According to our records, your registered trade name is listed as “<strong>{tradeName}</strong>” rather than “<strong>{hotelName}</strong>”.
          To ensure accurate processing and provide you with the most relevant details, please confirm if “<strong>{tradeName}</strong>” is correct or if it needs updating.
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <Button key="back" onClick={handleOkay} style={{ width: '48%' }}>
            Okay
          </Button>
          <Button key="switch" type="primary" onClick={switchToTradeName} style={{ width: '48%' }}>
            Switch Name
          </Button>
        </div>
      </Modal>
    </div>
  );
}
