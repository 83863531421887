import React, { useState } from "react";
import { Select, Tag } from "antd";
import axios from "axios";
import { API_ENDPOINT_HOTEL_SEARCH_GSTIN } from "../../hotelcontact.constants";
let timeout;
let currentValue;
const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const searchGSTINByText = async () => {
    const response = await axios.post(API_ENDPOINT_HOTEL_SEARCH_GSTIN, {
      query: value,
    });

    if (response.data.data.length === 0) {
      callback([
        {
          value: value,
          text: (
            <div>
              {value} <Tag color="red">New GSTIN</Tag>
            </div>
          ),
        },
      ]);
    } else {
      const data = response.data.data.map((item) => ({
        value: item._id,
        text: item._id,
      }));
      callback(data);
    }
  };

  if (value) {
    timeout = setTimeout(searchGSTINByText, 300);
  } else {
    callback([]);
  }
};
const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState();
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };
  const handleChange = (newValue) => {
    console.log("newVAlue:", newValue);
    setValue(newValue);
    props.onSelect && props.onSelect(newValue);
  };
  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};
const SearchGSTIN = (props) => (
  <SearchInput
    placeholder="Search GSTIN"
    style={{
      width: "100%",
    }}
    {...props}
  />
);
export default SearchGSTIN;
