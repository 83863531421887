import React, { createContext, useContext, useState } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({    
    // Hotel-specific details
    hotelDetails: {
      name: '',
      email: '',
      gstin: '',
      state: '',
      city: '',
    },
    personalDetails: {
      firstName: '',
      lastName: '',
      email: '',
      gstin: '',
      jobTitle: '',
    }
  });

  const updateFormData = (newData) => {
    setFormData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
