import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "./HotelContactManagement.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "../../shared/Loader";
import moment from "moment";
import {
  FileExclamationOutlined,
  CopyOutlined,
  RobotOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Input, Segmented } from "antd";

import { Badge, Button, Drawer, message, Modal, Tag, Tooltip } from "antd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Handlebars from "handlebars";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import HotelDataUpdate from "../../shared/HotelData";
import { param } from "jquery";
import SearchGSTIN from "./components/SearchGSTIN";
import HotelGSTINMapping from "./components/HotelGSTINMapping";
import {
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_HOTEL_GET_EMAIL_ANALYTICS,
  API_ENDPOINT_HOTEL_TRIGGER_MAIL,
  API_ENDPOINT_HOTEL_VIEW_LIST,
} from "./hotelcontact.constants";
import { useLocation } from "react-router-dom";

const colorOfEmailEvent = {
  processed: "green",
  delivered: "green",
  open: "green",
  click: "green",
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};

export default function InternalEmailAnalytics(props) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [searchQuery, setSearchQuery] = useState("");
  const [segmentValue, setSegmentValue] = useState("All");

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const EVENT_ICON_MAP = {
    processed: (color) => (
      <CheckOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    delivered: (color) => (
      <DoneAllOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    open: (color) => (
      <DraftsOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    click: (color) => (
      <AdsClickOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    acknowledge: (color) => (
      <AssignmentTurnedInIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
          marginRight: 28,
        }}
      />
    ),

    UNSUBSCRIBE: (color) => (
      <UnsubscribeOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    SPAMMED: (color) => (
      <PrivacyTipOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    bounce: (color) => (
      <CancelOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),

    dropped: (color) => (
      <MarkunreadMailboxOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),

    DEFFERED: (color) => (
      <ScheduleSendOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),
  };

  useEffect(() => {
    applySegmentFilter(segmentValue); // Apply the filter initially and when segment changes
  }, [segmentValue, gridApi]);

  const applySegmentFilter = (value) => {
    if (gridApi) {
      let filterModel;
      if (value === "Mail Sent") {
        filterModel = {
          email_sent_status: {
            filterType: "text",
            type: "equals",
            filter: "Yes",
          },
        };
      } else if (value === "Mail Not Sent") {
        filterModel = {
          email_sent_status: {
            filterType: "text",
            type: "equals",
            filter: "No",
          },
        };
      } else {
        filterModel = null; // Clear filters when "All" is selected
      }
      gridApi.setFilterModel(filterModel);
      gridApi.onFilterChanged();
    }
  };

  const onSegmentChange = (value) => {
    setSegmentValue(value); // Update segment value
  };

  const fetchTemplateInfo = async () => {
    const response = await axios.get(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-ed0fbc920d5149569f317cb6ccbfacb2"
    );
    if (response) {
      setTemplateData(response.data.html_content);
    }
  };

  const handleRenderEmailEvents = (eventData) => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: 6,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip placement="bottom" title={eventData?.event}>
            {EVENT_ICON_MAP[eventData?.event] ? (
              <Tag icon={EVENT_ICON_MAP[eventData?.event]("blue")}>
                {eventData?.event}
              </Tag>
            ) : (
              <Tag color="red">No Event</Tag>
            )}
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleAddGSTIN = (data) => {
    setSelectedHotel(data);
    setOpenDrawer(true);
  };

  const gridOptions = {
    rowModelType: "serverSide",
    serverSideStoreType: "partial", // For pagination
    cacheBlockSize: 100, // Number of rows per block (pagination size)
    paginationPageSize: 100, // Page size
  };

  const handleTriggerEmail = async (hotelInfo) => {
    try {
      const response = await axios.post(
        API_ENDPOINT_HOTEL_TRIGGER_MAIL + hotelInfo._id,
        {
          body: hotelInfo,
        }
      );
      console.log(response);

      if (response.data.status) {
        const actualDataEvent = response.data.event; // Assume 'event' holds your actual event data from the API
        const actualTimestamp = response.data.timestamp || new Date(); // Use current time if not provided

        messageApi.success({
          type: "success",
          content: `Email Sent to hotel ${hotelInfo.name}`,
        });

        // Use the grid API to update the row
        if (gridApi) {
          gridApi.forEachNode((node) => {
            if (node.data._id === hotelInfo._id) {
              // Update the row node data using actual API data
              node.setData({
                ...node.data,
                last_email: { timestamp: actualTimestamp }, // Use actual timestamp
                last_email_event: { event: actualDataEvent || "Sent" }, // Update the email log with actual event
                email_sent_status: true, // Set email sent status to true
              });
            }
          });
        }
      } else {
        messageApi.error({
          type: "error",
          content: response.data.message || "Failed to send email",
        });
      }
    } catch (error) {
      messageApi.error({
        type: "error",
        content: "An error occurred while sending email",
      });
      console.error(error);
    }
  };

  const handleCopyData = (data) => {
    if (navigator.clipboard) {
      // Clipboard API supported
      navigator.clipboard
        .writeText(data)
        .then(() => {
          console.log("Text copied to clipboard");
          messageApi.success({
            type: "success",
            content: `${data} copied`,
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Clipboard API not supported, fallback to older method
      messageApi.open({
        type: "info",
        content: `Please copy manually something went wrong`,
      });
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);

    if (gridApi) {
      gridApi.setFilterModel({
        "Hotel Name": {
          filterType: "text",
          type: "contains",
          filter: value,
        },
        address: {
          filterType: "text",
          type: "contains",
          filter: value,
        },
      });
      gridApi.onFilterChanged();
    }
  }, 500);

  const onSearchChange = useCallback(
    (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  function generateGmailSearchLink(messageId) {
    // Encode the message ID to make it URL-safe
    const encodedMessageId = encodeURIComponent(messageId);

    // Construct the Gmail search URL
    const gmailSearchUrl = `https://mail.google.com/mail/u/5/#search/rfc822msgid:${encodedMessageId}`;

    return gmailSearchUrl;
  }

  const columnDefs = [
    {
      field: "template",
      headerName: "Template",
      width: 300,
      cellRenderer: (params) => {
        return (
          <Tag
            icon={<FileExclamationOutlined />}
            color="blue"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                generateGmailSearchLink(params.data?.mainThreadId),
                "_blank"
              );
            }}
          >
            Followup Template
          </Tag>
        );
      },
    },

    {
      field: "hotelId",
      headerName: "Hotel Id",
      width: 200,
      filter: "agTextColumnFilter",
    },

    {
      field: "Hotel Name",
      headerName: "Hotel Name",
      width: 300,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        const handleHotelNameClick = () => {
          const hotelId = params.data._id;
          const url = `https://crm.zoho.in/crm/org60016690758/tab/Accounts/${params.data?.zoho_crm_id}`;
          window.open(url, "_blank"); // Open the URL in a new tab
        };

        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={handleHotelNameClick}
          >
            <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />
            {params.data["Booking ID/Invoice No"] || params.value}
          </div>
        );
      },
    },

    {
      field: "email_logs",
      headerName: "Email Events",
      children: [
        {
          field: "email_sent",
          headerName: "Sent",
          minWidth: 100,
          enableRowGroup: true,
          cellRenderer: (params) => {
            return params.data?.email_events?.length > 0 ? (
              <CheckOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "#007cc2",
                  cursor: "pointer",
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "gray",
                  cursor: "pointer",
                }}
              />
            );
          },
        },
        {
          field: "email_delivered",
          headerName: "Delivered",
          enableRowGroup: true,
          cellRenderer: (params) => {
            let isDelivered = (params.data.email_events || []).find(
              (item) => item.event === "delivered"
            );
            return isDelivered ? (
              <CheckOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "#007cc2",
                  cursor: "pointer",
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "gray",
                  cursor: "pointer",
                }}
              />
            );
          },
        },
        {
          field: "email_open",
          headerName: "Opened",
          enableRowGroup: true,
          cellRenderer: (params) => {
            let isOpened = (params.data.email_events || []).find(
              (item) => item.event === "open"
            );
            return isOpened ? (
              <CheckOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "#007cc2",
                  cursor: "pointer",
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "gray",
                  cursor: "pointer",
                }}
              />
            );
          },
        },
        {
          field: "email_click",
          enableRowGroup: true,
          headerName: "Clicked",
          cellRenderer: (params) => {
            let isClicked = (params.data.email_events || []).find(
              (item) => item.event === "click"
            );
            return isClicked ? (
              <CheckOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "#007cc2",
                  cursor: "pointer",
                }}
              />
            ) : (
              <CloseOutlinedIcon
                style={{
                  fontSize: 18,
                  color: "gray",
                  cursor: "pointer",
                }}
              />
            );
          },
        },
      ],
    },
    {
      field: "last_mail_sent",
      headerName: "Email Sent At",
      width: 300,
      valueGetter: (params) => {
        return (
          params.data.sentAt &&
          params.data.sentAt &&
          moment(params.data.sentAt).format("DD-MM-YYYY HH:mm") // Use HH:mm for 24-hour format
        );
      },
    },
    {
      field: "email_replied",
      headerName: "Email Replied",
      width: 300,
      cellRenderer: (params) => {
        return (
          <Tag
            icon={<MessageOutlined />}
            color={params.data?.email_replied ? "green" : "red"}
          >
            {params.data?.email_replied ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      field: "ai_processed",
      headerName: "AI Processed Email",
      width: 300,
      cellRenderer: (params) => {
        return (
          <Tag
            icon={<RobotOutlined />}
            color={params.data?.ai_processed ? "green" : "red"}
          >
            {params.data?.ai_processed ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      field: "tag_added",
      headerName: "Tags Added",
      width: 300,
      cellRenderer: (params) => {
        return (
          <div>
            {(params.data?.tag_added || []).map((item) => {
              return <Tag color="orange">{item}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      field: "suggested_response",
      headerName: "AI Response",
      width: 300,
    },
    {
      field: "contact_received",
      headerName: "Total Contact Received",
      width: 300,
    },
    {
      field: "gstin_confirmed",
      headerName: "GSTIN Confirmed",
      width: 300,
      cellRenderer: (params) => (
        <Tag color={params.data?.gstin_confirmed ? "green" : "red"}>
          {params.data?.gstin_confirmed ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      field: "manual_intervention",
      headerName: "Manual Intervention",
      width: 300,
      cellRenderer: (params) => (
        <Tag color={params.data?.manual_intervention ? "red" : "green"}>
          {params.data?.manual_intervention ? "Yes" : "No"}
        </Tag>
      ),
    },
  ];

  useEffect(() => {
    // fetchHotelList();
    fetchTemplateInfo();
  }, []);

  useEffect(() => {
    handlePreDataFilter();
  }, [gridApi]);
  const handlePreDataFilter = () => {
    const hotelId = queryParams.get("hotelId");
    if (gridApi && hotelId) {
      console.log("hotelId:", hotelId);

      gridApi.setFilterModel({
        hotelId: {
          filterType: "text",
          type: "contains",
          filter: hotelId,
        },
      });
      gridApi.onFilterChanged();
    }
  };
  const fetchHotelList = async () => {
    try {
      const response = await axios.get(API_ENDPOINT_HOTEL_VIEW_LIST);

      setRowData(response.data.data);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
    setLoading(false);
  };

  const datasource = {
    getRows: function (params) {
      // Use the fetched data to update the grid
      fetch(API_ENDPOINT_HOTEL_VIEW_LIST, {
        method: "POST",
        body: JSON.stringify({
          ...params.request,
          ...props.serverPayload,
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((httpResponse) => httpResponse.json())
        .then((response) => {
          params.success({
            rowData: response.data.rows,
            rowCount: response.data.lastRow,
            ...(response.data.pivotResultFields &&
            response.data.pivotResultFields.length > 0
              ? { pivotResultFields: response.data.pivotResultFields }
              : {}),
          });

          // handleColumnRowGroupChanged(params);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);

    // Set the server-side datasource here
    const datasource = {
      getRows: (params) => {
        const request = params.request;
        console.log("Payload:", request);

        fetch(API_ENDPOINT_HOTEL_GET_EMAIL_ANALYTICS, {
          method: "POST",
          body: JSON.stringify(request),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            params.success({ rowData: data.data });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            params.fail();
          });
      },
    };

    // Set the server-side datasource
    params.api.setGridOption("serverSideDatasource", datasource);
  };

  const renderTemplate = (htmlContent, data = {}) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler({ hotel_name: data?.name });

    return renderedContent;
  };

  const gridRef = useRef();

  return (
    <div className="HotelContactManagement">
      <div className="MainContainer">
        <div
          className="SearchContainer"
          style={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            margin: "15px",
          }}
        >
          <Segmented
            options={["All", "Mail Sent", "Mail Not Sent"]}
            onChange={onSegmentChange}
          />
          <Input
            placeholder="Search by Hotel Name or Address"
            style={{ width: 350 }}
            onChange={onSearchChange}
          />
        </div>
        <div className="ag-theme-alpine" style={{ height: "90%" }}>
          <AgGridReact
            rowModelType="serverSide" // Important: Enables server-side row model
            cacheBlockSize={100} // Optional: number of rows to load at once
            paginationPageSize={100} // Optional: set page size
            // defaultColDef={{ resizable: true, flex: 1 }}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            ref={gridRef}
            rowGroupPanelShow={"always"}
            pagination={true}
          />
        </div>
      </div>
      <Modal
        title={"Update Hotel Contact"}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
        width={showModal === "TEMPLATE_VIEW" ? 800 : 600}
      >
        {showModal === "TEMPLATE_VIEW" ? (
          <div
            style={{ height: "70vh", overflowY: "scroll" }}
            dangerouslySetInnerHTML={{
              __html: renderTemplate(templateData || "", selectedBooking),
            }}
          ></div>
        ) : (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              fetchHotelList();
              // fetchBookingData();
            }}
          />
        )}
      </Modal>

      <Drawer
        title="Map Your GSTIN"
        placement={"right"}
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        key={"right"}
      >
        <HotelGSTINMapping
          hotelData={selectedHotel}
          onSuccess={() => {
            setOpenDrawer(false);
          }}
        />
      </Drawer>
      {contextHolder}
    </div>
  );
}
