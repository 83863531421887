export const API_ENDPOINT_FETCH_TEMPLATE_INFO = `${process.env.REACT_APP_API_URL}/misc/sendgrid/template/`;
export const API_ENDPOINT_HOTEL_TRIGGER_MAIL = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/email/trigger/`;
export const API_ENDPOINT_HOTEL_UPDATE_INFO = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/gstin/update/`;
export const API_ENDPOINT_HOTEL_SEARCH_GSTIN = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/gstin/search`;
export const API_ENDPOINT_HOTEL_VIEW_LIST = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/list`;
export const API_ENDPOINT_HOTEL_DETAILS_UPDATE = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/update/contact/`;
export const API_ENDPOINT_HOTEL_CONTACT_UPDATE_META = `${process.env.REACT_APP_API_URL}/agtable/collection/update`;
export const API_ENDPOINT_HOTEL_UPDATE_META_INFO = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/meta/update/`;
export const API_ENDPOINT_HOTEL_LIST_NEW = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/listnew`;
export const API_ENDPOINT_HOTEL_CONTACT_CONFIRMATION_FOLLOWUP = `${process.env.REACT_APP_API_URL}/hotelgst/hotel/email/contact_confrimation`;
