import React, { useEffect, useState } from 'react';
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';

const images = [img1, img2, img3, img4];

const Sidecontent = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  return (
    <div style={styles.sideContainer}>
      <div
        style={{
          ...styles.imageSlider,
          transform: `translateX(-${currentImage * 100}%)`,
        }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            style={styles.image}
          />
        ))}
      </div>
      <div style={styles.textContainer}>
        <h3 style={styles.infoHeading}>
        HotelsGST boosts companies' Input GST claims while keeping hotels fully compliant.
        </h3>
          <svg xmlns="http://www.w3.org/2000/svg" width="179" height="6" viewBox="0 0 179 6" fill="none">
            <path d="M3 3H176" stroke="white" stroke-width="5" stroke-linecap="round"/>
          </svg>
        <div style={styles.footer}>
          <p style={styles.infoText}>
          HotelsGST Score provides companies with insights into a hotel's GST compliance. Our platform ensures the flow of valid GST invoices, enabling companies to claim input tax credit on hotel expenses.
          </p>
          <div style={styles.dotsContainer}>
            {images.map((_, index) => (
              <div
                key={index}
                style={{
                  ...styles.dot,
                  backgroundColor: currentImage === index ? '#fff' : '#fff',
                  transform: currentImage === index ? 'scale(1)' : 'scale(0.7)', // Increase size when active
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  sideContainer: {
    flex: 1.1,
    position: 'relative',
    borderRadius: '10px', // Rounded corners for the card effect
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  imageSlider: {
    display: 'flex',
    transition: 'transform 1s ease-in-out',
    width: '100%', // Ensure that all images are in one line
  },
  image: {
    width: '100%',
    height: 'auto', 
    flex: '1 0 100%' // Ensure each image takes full width
  },
  textContainer: {
    position: 'absolute',
    bottom: 0,
    padding: '50px 30px', // More padding at top and bottom
    backgroundColor: 'transparent',
    color: '#fff',
    width: '100%',
  },
  infoHeading: {
    fontSize: '20px',
    marginBottom: '10px',
    color:'#fffff'
  },
  boldLine: {
    border: '0',
    height: '4px',
    backgroundColor: '#fffff',
    width: '100px',
    color:'#fffff'
  },
  infoText: {
    fontSize: '16px',
    color: '#FFFFFF',
  },
  footer:{
    display:'flex',
    gap:'150px'
  },
  dotsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '10px',
  },
  dot: {
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    margin: '0 5px',
    transition: 'background-color 0.5s ease, transform 0.3s ease', // Added transition for smooth scaling
  },
};

export default Sidecontent;