import { useEffect, useState, useRef } from "react";
import "./TriggerHotelSingleEmail.scss";
import Loader from "../../shared/Loader";
import { CheckCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { API_ENDPOINT_TRIGGER_HOTEL_EMAIL_VIA_CRM } from "../InternalEmailAnalytics/hotelcontact.constants";
import { useLocation, useParams } from "react-router-dom";

export default function TriggerHotelSingleEmail(props) {
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const priority = query.get("priority");
  const { crmId } = useParams();

  // Track if API call has been made
  const hasCalledAPI = useRef(false);

  const handleTriggerEmail = async () => {
    setLoading(true);
    try {
      await axios.post(API_ENDPOINT_TRIGGER_HOTEL_EMAIL_VIA_CRM, {
        crmId,
        priority,
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Prevent multiple calls on initial load
    if (!hasCalledAPI.current) {
      handleTriggerEmail();
      hasCalledAPI.current = true;
    }
  }, []); // Only run once on initial mount

  return (
    <div className="TriggerHotelSingleEmail">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="SuccessContainer">
          <CheckCircleFilled style={{ fontSize: 88, color: "#27ae60" }} />
          <h5 style={{ marginTop: 18 }}>Email Sent</h5>
          <p>We have sent the mail to the hotel on your behalf</p>
        </div>
      )}
    </div>
  );
}
