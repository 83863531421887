import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { Typography, Box, Grid, Stack } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import '../pages/demo/MainContent/mainContent.css';
import VendorCard from "../pages/demo/VendorCard/VendorCard";
import BlankCard from "../pages/demo/BlankCard/BlankCard";
import CustomGaugeChart from "../pages/demo/meter/meter";
import MapChart from "../pages/demo/Mapchart/Mapchart";
import BarGraph from "../pages/demo/Delay_In_Filing_Gstr1/BarGraph";
import GSTR3BFilingStatusSummary from "../pages/demo/Gstr3B_filing_satus/GSTRBFilingStatus";
import GSTR31FilingStatusSummary from "../pages/demo/Gstr31_filing_status/GSTR31FilingStatusSummary";
import VendorscoreGraph from "../pages/demo/vendorscorehistory/vendorhistory";
import CurrentScore from "../pages/demo/Current_Scores/CurrentScoreTable";
import BarGraph1 from "../pages/demo/Delay_In_Gstr3b/BarGraph1";
import GSTTable from "../pages/demo/gst_table/gst_table";
import LineGraph from "../pages/demo/Liabilitiesgraph/liabilitiesgraph";
import SimpleGauge from "../NewPage/Meter";
import warn from "../assets/WarningCircle.png";
import ximg from "../assets/XCircle.png";
import mapimg from "../assets/MapPin.png";
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { height, width } from '@fortawesome/free-solid-svg-icons/fa0';




const CheckScorePage = () => {
  const search = useLocation().search;
  const gstin = new URLSearchParams(search).get('gstin');
  const [legalName,setLegalName] = useState("Not Available")
  const [gstData, setGstData] = useState({});
  const [lineGraphData, setLineGraphData] = useState({ labels: [], data: [] });
  const [rgdt, setRgdt] = useState("Not Available"); // State for Date of Registration
  const [aggreTurnOver, setAggreTurnOver] = useState("Not Available"); // State for Annual Aggregate Turnover
  const [EinvoiceStatus,setEinvoiceStatus] = useState("Not Available")
  const [Address,setAddress] = useState("Not Available")
  const [selectedGstin, setSelectedGstin] = useState("");
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchGstData = async () => {
      try {
        const response = await fetch('http://localhost:5000/gstin-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: gstin }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          
          // Update selectedGstin state
          setSelectedGstin(data);
        } else {
          console.error('Failed to fetch GSTIN data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching GSTIN data:', error);
      }
    };

    // Call fetch function
    if (gstin) {
      fetchGstData();
    }
  }, [gstin]);


useEffect(() => {
  if (selectedGstin) {
    console.log("selectedGstin:", selectedGstin);

    // Handle liability history and additional info
    if (selectedGstin.liabilityhistory) {
      const { liabilityhistory } = selectedGstin;
      
      // Extract month and percentage data
      const labels = liabilityhistory.prevdtls.map(item => item.taxperiod);
      const data = liabilityhistory.prevdtls.map(item => item.liab_pct);
      console.log(data);
      
      // Set the data for the LineGraph
      setLineGraphData({ labels, data });
    } else {
      console.log('No liability history available.');
      setLineGraphData({ labels: [], data: [] });
    }

    const { info } = selectedGstin;
    if (info) {
      setRgdt(info.rgdt || "Not Available");
      setAggreTurnOver(info.aggreTurnOver || "Not Available");
      setLegalName(info.lgnm || "Not Available")
      const einvoiceStatus = info.einvoiceStatus;
      if (einvoiceStatus === "Yes") {
        setEinvoiceStatus("Eligible");
      } else if (einvoiceStatus === "No") {
        setEinvoiceStatus("Not Eligible");
      } else {
        setEinvoiceStatus("Not Available");
      }
    }
    const {pob} = selectedGstin
    if(pob){
      const address = pob?.pradr?.adr || "Not Available";
      setAddress(address);
    }
  } else {
    console.log('No matching GSTIN found.');
    setLineGraphData({ labels: [], data: [] });
    setRgdt("Not Available");
    setAggreTurnOver("Not Available");
  }
}, [selectedGstin]);
const handleUnlockScoreClick = () => {
  navigate('/signup');
};



  return (
    <div style={styles.NewpageContainer} >
    <nav style={styles.secondNav}>
      <div style={styles.inputContainer}>
        <img src={mapimg} style={styles.mapimg} />
        <input
          type="text"
          style={styles.input}
        />
        <img src={ximg} style={styles.ximg} />
        {/* <button style={styles.showAllButton}>Show All</button> */}
      </div>
    </nav>
    <div style={styles.container}>
      <div style={styles.maincard}>
      <div style={styles.card}>
        <div style={styles.Topblock} >
          <div style={styles.block1}>
          <div style={styles.cardTitlediv1}>
            <h2 style={styles.cardTitle}>{legalName}</h2>
            <p style={styles.cardText}>
            {Address}
            </p>
          </div>
            <div style={styles.cardTextdiv1}>
              <p style={styles.cardText1}><span style={styles.spanstyle}>GSTIN</span> {gstin}</p>
              <p style={styles.cardText1}><span style={styles.spanstyle}>Entity Name</span> ORIENTAL HOTELS LTD</p>
            </div>
            <div style={styles.cardTextdiv}>
              <p style={styles.cardText1}>E-Invoice <span style={styles.spanstyle}>{EinvoiceStatus}</span> </p>
              <p style={styles.cardText1}>Filing Frequency <span style={styles.spanstyle}>Monthly</span> </p>
            </div>
          </div>
          <div style={styles.infoBlock}>
            <div style={styles.infoItem}>
              <div>No. of months delayed 3B</div>
              <div style={styles.int}>0</div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1"
              height="62"
              viewBox="0 0 1 62"
              fill="none"
            >
              <path
                d="M0.5 1L0.499997 61"
                stroke="#CDCDCD"
                stroke-linecap="round"
              />
            </svg>
            <div style={styles.infoItem}>
              <div>Avg delay in day(s) 3B</div>
              <div style={styles.int}>0</div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1"
              height="62"
              viewBox="0 0 1 62"
              fill="none"
            >
              <path
                d="M0.5 1L0.499997 61"
                stroke="#CDCDCD"
                stroke-linecap="round"
              />
            </svg>
            <div style={styles.infoItem}>
              <div>No. of months delayed R1</div>
              <div style={styles.int}>0</div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1"
              height="62"
              viewBox="0 0 1 62"
              fill="none"
            >
              <path
                d="M0.5 1L0.499997 61"
                stroke="#CDCDCD"
                stroke-linecap="round"
              />
            </svg>
            <div style={styles.infoItem}>
              <div>Avg delay in day(s) R1</div>
              <div style={styles.int}>0</div>
            </div>
          </div>
        </div>
        <div style={styles.metercard}>
          <div style={styles.blurContainer}>
            <SimpleGauge style={styles.meter} />
          </div>
          <button style={styles.viewbtn} onClick={handleUnlockScoreClick}>
              <LockIcon style={styles.lockIcon} />
              Unlock Score
            </button>
        </div>
      </div>
      <div style={styles.card2}>
        <div style={styles.footer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 61 60" fill="none" style={styles.warning} >
  <path d="M30.5 5.625C25.6791 5.625 20.9664 7.05457 16.958 9.73293C12.9495 12.4113 9.82533 16.2181 7.98045 20.6721C6.13556 25.126 5.65286 30.027 6.59337 34.7553C7.53388 39.4836 9.85538 43.8268 13.2643 47.2357C16.6732 50.6446 21.0164 52.9661 25.7447 53.9066C30.473 54.8472 35.374 54.3644 39.8279 52.5196C44.2819 50.6747 48.0887 47.5505 50.7671 43.542C53.4454 39.5336 54.875 34.8209 54.875 30C54.8682 23.5374 52.2979 17.3415 47.7282 12.7718C43.1585 8.20209 36.9626 5.63182 30.5 5.625ZM41.2016 25.7016L28.0766 38.8266C27.9024 39.0009 27.6956 39.1392 27.468 39.2335C27.2404 39.3279 26.9964 39.3765 26.75 39.3765C26.5036 39.3765 26.2596 39.3279 26.032 39.2335C25.8044 39.1392 25.5976 39.0009 25.4234 38.8266L19.7984 33.2016C19.4466 32.8497 19.249 32.3726 19.249 31.875C19.249 31.3774 19.4466 30.9003 19.7984 30.5484C20.1503 30.1966 20.6275 29.999 21.125 29.999C21.6226 29.999 22.0997 30.1966 22.4516 30.5484L26.75 34.8492L38.5484 23.0484C38.7227 22.8742 38.9295 22.736 39.1571 22.6418C39.3847 22.5475 39.6286 22.499 39.875 22.499C40.1214 22.499 40.3653 22.5475 40.5929 22.6418C40.8206 22.736 41.0274 22.8742 41.2016 23.0484C41.3758 23.2226 41.514 23.4295 41.6082 23.6571C41.7025 23.8847 41.7511 24.1286 41.7511 24.375C41.7511 24.6214 41.7025 24.8653 41.6082 25.0929C41.514 25.3205 41.3758 25.5274 41.2016 25.7016Z" fill="#007CC2"/>
</svg>
          <p style={styles.footerdes}>
          Enter your email address and <br></br>get a free demo now!
          </p>
        </div>
        <div style={styles.footer}>
        {/* <button style={styles.Loginbtn}>Log In</button>
         */}
          <input
            type="text"
            placeholder="Enter First Name"
            style={styles.inputfooter}
          />
        <Link to='/signup' ><button style={styles.viewbtn2}>Get Demo</button></Link>
        </div>
      </div>
      </div>
    </div>
  </div>
  );
  
};

const styles = {
  footer:{
    display:'flex',
    gap:'10px'
  },
  inputfooter: {
    borderRadius:'5px',
    border: '1px solid #007CC2',
    fontSize: '15px',
    paddingLeft:'5px',
    width:'150%',
    height:'40px',
  },
  secondNav: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff", // Adjust based on your image's background color
    padding: "10px 357px",
    marginTop:'30px'    
  },
  blurContainer: {
    position: "relative",
    filter: "blur(8px)", // Apply blur effect
    paddingLeft:'100px'
  },
  viewScoreBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the button
    backgroundColor: "#1ABC9C",
    color: "#fff",
    border: "none",
    transform: "translate(-50%, -50%)", 
    padding: "10px 25px",
    borderRadius: "10px",
    cursor: "pointer",
    zIndex: 1, // Ensure the button is on top
  },
  lockIcon: {
    marginRight: "8px", // Space between the icon and the text
  },
  inputContainer: {
    position: "relative",
    flex: 1,
  },
  input: {
    width: "100%",
    padding: "10px 20px 10px 40px", // Left padding adjusted to accommodate the location icon
    borderRadius: "50px",
    border: "2px solid #ccc",
  },
  mapimg: {
    position: "absolute",
    left: "10px", // Adjusted to move the icon to the correct position inside the input field
    top: "50%",
    transform: "translateY(-50%)", // Vertically center the icon
    height: "20px", // Adjusted height to better match the design
  },
  ximg: {
    position: "absolute",
    right: "20px", // Adjusted to position correctly inside the input field
    top: "50%",
    transform: "translateY(-50%)", // Vertically center the icon
    height: "20px",
  },
  showAllButton: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)", // Vertically center the button
    backgroundColor: "#1ABC9C",
    color: "#fff",
    border: "none",
    padding: "0 15px",
    borderRadius: "10px",
    cursor: "pointer",
    height: "35px",
  },
  container: {
    padding: "50px 90px",
    backgroundColor: "#ffffff",
    fontFamily: "Arial, sans-serif",
  },
  card: {
    display: "flex",
    backgroundColor: "#f8f8f8",
    marginBottom: "20px",
    gap:'10px'
    
  },
  maincard:{
    backgroundColor: "#f8f8f8",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    fontSize: "20px",
    marginBottom: "40px",
    fontWeight: "bold",
    color:'#007CC2'
  },
  cardText: {
    fontSize: "14px",
    marginBottom: "5px",
    width: "400px",
    color:'#555'
  },
  cardText1: {
    fontSize: "14px",
    marginBottom: "5px",
    color:'#555'
  },
  infoBlock: {
    display: "flex",
    gap: "20px",
    backgroundColor:'#ffff',
    padding:'25px 0px',
    borderRadius:'5px',
    justifyContent:'center',
    alignItems:'center'
  },
  infoItem: {
    textAlign: "center",
    padding:'0px 25px',
    color:'#555',
    fontSize:'14px',
  },
  verticalLine: {
    width: "5px",
    height: "100%",
    backgroundColor: "black",
  },
  emailInput: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ced4da",
    width: "250px",
    marginRight: "10px",
  },
  startTrialButton: {
    padding: "8px 15px",
    backgroundColor: "#00b074",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cardTitlediv1: {
    display: "flex",
    flexDirection:'column',
    backgroundColor:'#ffff',
    borderRadius: '5px',
    width:'450px',
    padding:'20px',
    paddingLeft:'35px',
    width:'100%'
  },
  viewbtn: {
    backgroundColor: "#555555",
    color: "#fff",
    border: "none",
    padding: "10px 35px",
    borderRadius: "10px",
    position: "absolute",
    top: "50%",
  },
  viewbtn2: {
    backgroundColor: "#007CC2",
    color: "#fff",
    border: "none",
    marginLeft: "30px",
    width:'150%',
    height:'40px',
    borderRadius: "10px",
  },
  Loginbtn:{
    backgroundColor: "transparent",
    color: "#007CC2",
    border: "solid 1px ",
    padding: "10px 25px",
    marginLeft: "30px",
    borderRadius: "10px",
  },
  metercard: {
    width: "280px",
    backgroundColor:'#ffff',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'5px'
  },
  card2: {
    display: "flex",
    backgroundColor: "#ebf1f5",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    flexDirection: "column",
    marginTop: "-10px",
  },
  footer: {
    display: "flex",
    marginBottom: "10px",
  },
  footerdes: {
    marginTop: "10px",
    color:'#007CC2',
    fontSize:'15px'
  },
  warning: {
    width: "40px",
    marginRight: "10px",
    paddingBottom:'10px'
  },
  int:{
    color:"#007CC2",
    marginTop:'10px'
  },
  cardTitle1: {
    position: "absolute",
    right: "60px",
    backgroundColor: "#FEEA88", // Yellow background color
    color: "#2F4858", // Text color (dark blue)
    padding: "8px 12px", // Padding around the text
    fontSize: "16px", // Font size for the text
    fontWeight: "bold", // Makes the text bold
    display: "flex", // Flexbox to align items
    alignItems: "center", // Vertical alignment
    gap: "8px", // Space between the icon and text
    height: "40px",
    marginBottom: "30px",
  },
  icon: {
    fontSize: "18px", // Adjust the size of the icon
  },
  cardTitlediv: {
    display: "flex",
  },
  cardTextdiv1: {
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
    padding:'0px 20px',
    gap: "20px",
    backgroundColor:'#ffff',
    borderRadius: '5px',
    minWidth:'260px',
    minHeight:'150px'
  },
  cardTextdiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
    padding:'0px 20px',
    gap: "20px",
    backgroundColor:'#ffff',
    borderRadius: '5px',
  },
  spanstyle:{
    color:'#007CC2',
    fontWeight:'Bold'
  },
  NewpageContainer:{
    width:'100%',
    position:'absolute',
    fontFamily: "Noto Sans",
    top:'10%'
  },
  block1:{
    display:'flex',
    gap:'10px'
  },
  Topblock:{
    display:'flex',
    flexDirection:'column',
    gap:'10px',
  }
  
};


export default CheckScorePage;
