import React, { useState, Fragment } from "react";
import { AgCharts } from "ag-charts-react";

function getData() {
  return [
    { year: "2017-2018", score: 45 },
    { year: "2018-2019", score: 60 },
    { year: "2019-2020", score: 50 },
    { year: "2020-2021", score: 80 },
    { year: "2021-2022", score: 84 },
    { year: "2022-2023", score: 60 },
  ];
}

const ChartExample = () => {
  const [options, setOptions] = useState({
    title: {
      text: "Vendor Score History (Yearly)",
    },
    data: getData(),
    series: [
      {
        type: "line",
        xKey: "year",
        xName: "Year",
        yKey: "score",
        yName: "Score",
        stroke: "#0a8394",  // Set the line color
        strokeWidth: 3,
        interpolation: { type: "line" },
        marker: {
          enabled: true,  // Disable markers by default
          size: 10,
          fill: 'white',  // Set the marker fill color
          stroke: "#0a8394",  // Set the marker stroke color
          strokeWidth: 2,
        },
        highlightStyle: {
          item: {
            enabled: true,  // Show the marker when hovering
            fill: '#0a8394',
            stroke: '#0a8394',
            strokeWidth: 3,
            size: 10,  // Marker size when hovered
          },
        },
        tooltip: {
          enabled: true,
          renderer: (params) => {
            return {
              content: `${params.datum.year}: ${params.datum.score}%`,
            };  // Display the correct percentage with the year
          },
        },
        fillOpacity: 0.3,
      },
    ],
    // axes: [
    //   {
    //     type: "category",
    //     position: "bottom",
    //     title: { text: "Year" },
    //   },
    //   {
    //     type: "number",
    //     position: "left",
    //     title: { text: "Score" },
    //     min: 20,
    //     max: 100,
    //   },
    // ],
  });

  const chartStyle = {
    height: '400px', 
    width: '440px', 
  };

  return (
    <div>
      <AgCharts options={options} style={chartStyle} />
    </div>
  );
};

export default ChartExample;
