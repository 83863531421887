import { Button, Checkbox, Input, message, Select } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT_HOTEL_INFO_STAFF_CONTACT_VERIFY } from "../../landing.page.constant";
export default function HotelVerification(props) {
  const [otp, setOTP] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleOTPVerification = async () => {
    const payload = {
      otp: otp,
    };
    if (!otp) {
      messageApi.error({
        type: "error",
        content: "Please enter your OTP!",
      });
      return;
    }

    const response = await axios.post(
      `${API_ENDPOINT_HOTEL_INFO_STAFF_CONTACT_VERIFY}${props?.hotelInfo?.insertedId}/otp/verify`,
      payload
    );
    if (response.status) {
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Something went wrong while otp verification",
      });
    }
    props.onSuccess && props.onSuccess();
  };
  return (
    <div className="HotelInfoForm">
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>OTP</small>
        <br />
        <Input placeholder="OTP" onChange={(e) => setOTP(e.target.value)} />
      </div>

      <div style={{ marginTop: 12 }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={handleOTPVerification}
        >
          Proceed
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
