import React, { useState, useEffect, useRef, useCallback } from "react";
import "./HotelBookingPage.scss";
import HeaderNew from "../shared/Header";
import {
  FileTextOutlined,
  GlobalOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  CloseOutlined,
  UnlockOutlined,
  CopyOutlined,
  CheckOutlined,
  MailOutlined,
} from "@ant-design/icons";
import HotelIcon from "../../src/assets/hoteicon.png";
import { Button, Input, message, Modal, Tag, Card } from "antd";
import { AgGridReact } from "ag-grid-react";
import Uploader from "../shared/Uploader";
import axios from "axios";
import {
  API_ENDPOINT_HOTEL_GET_BOOKING,
  API_ENDPOINT_HOTEL_INFO,
  API_ENDPOINT_STORAGE_S3_UPLOAD_OPEN,
} from "./hotel.booking.constant";
import { useParams } from "react-router-dom";
import Loader from "../shared/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import table_gif from "../../src/assets/table_gif.png";
import vector_gst from "../../src/assets/Vector_gstnotify.png";
import { API_ENDPOINT_TRIGGER_HOTEL_EMAIL_VIA_CRM } from "../scenes/InternalEmailAnalytics/hotelcontact.constants";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const HotelBookingPageInternal = () => {
  const [showModal, setShowModal] = useState(false);
  const [hotelInfo, setHotelInfo] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [bookingList, setBookingList] = useState([]);
  const { hotelId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showGSTINModal, setShowGSTINModal] = useState(false);
  const [missingGSTINIndex, setMissingGSTINIndex] = useState(null);
  const [gstinInput, setGstinInput] = useState("");
  const [showBottomRightCard, setShowBottomRightCard] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const response = await axios.get(
          "https://dev-backend.finkraftai.com/api/bookings"
        );
        if (response.data) {
          const currentData = response.data.map((item) => ({
            parseddata: !!item.ParsedData, // Corrected the syntax here
            parsedhotelGstin:
              item.ParsedData?.[0]?.finalData?.Hotel_Gstin || "...", // Get Hotel_Gstin
            invoiceNumber:
              item.ParsedData?.[0]?.finalData?.Invoice_Number || "...", // Get Invoice_Number
            totalAmount: item.ParsedData?.[0]?.finalData?.Total_Amount || "...", // Get Total_Amount
            totalTaxAmount:
              item.ParsedData?.[0]?.finalData?.Total_Tax_Amount || "...", // Get Total_Tax_Amount
            invoiceDate: item.ParsedData?.[0]?.finalData?.Invoice_Date || "...",
            s3Link: item.ParsedData?.[2]?.s3url || null,
            boundingBoxes: item.ParsedData?.[1]?.boundingBoxes || null,
            city: item.booking_data?.segments?.property?.address?.city?.name,
            check_in_date:
              item.booking_data?.tripDetails?.tripStartDateTime || null,
            data: item["Booking ID/Invoice No"] || null,
            hotel_name: item["Hotel Name"] || null,
            client_gstin: item["Client GSTIN"] || null,
            client_name: item["Client Name"] || null,
            hotel_gstin: item["Hotel GSTIN"] || null,
            hotel_address: item["Hotel Address"] || null,
            invoice_no: item["Booking ID/Invoice No"] || null,
            check_out_date: item["Date of Invoice/Checkout Date"] || null,
            total_gst: item["Total GST"] || null,
            _id: item["_id"],
          }));
          setRowData(currentData);
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };
    fetchBookingData();
  }, []);

  const handleTriggerEmail = async (crmId, priority) => {
    try {
      await axios.post(API_ENDPOINT_TRIGGER_HOTEL_EMAIL_VIA_CRM, {
        crmId,
        priority,
      });
      messageApi.success({
        type: "success",
        content: `Email has been sent`,
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleCopyData = (data) => {
    if (navigator.clipboard) {
      // Clipboard API supported
      navigator.clipboard
        .writeText(data)
        .then(() => {
          console.log("Text copied to clipboard");
          messageApi.success({
            type: "success",
            content: `${data} copied`,
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Clipboard API not supported, fallback to older method
      messageApi.open({
        type: "info",
        content: `Please copy manually something went wrong`,
      });
    }
  };

  const columnDefs = [
    {
      enableRowGroup: true,
      field: "Invoice",
      headerName: "Invoice",
      width: 200,
      cellRenderer: (params) => {
        const isEdit = params.data && params.data.parseddata ? true : false;

        const handleButtonClick = () => {
          navigate("/invoiceform", {
            state: {
              isEdit: isEdit, // Set isEdit based on the presence of parseddata
              check_out_date: params.data["Date of Invoice/Checkout Date"],
              data: params.data["Booking ID/Invoice No"],
              hotel_name: params.data["Hotel Name"],
              client_gstin: params.data["Client GSTIN"],
              client_name: params.data["Client Name"],
              hotel_gstin: params.data["Hotel GSTIN"],
              hotel_address: params.data["Hotel Address"],
              invoice_no: params.data["Booking ID/Invoice No"],
              total_gst: params.data["Total GST"],
              _id: params.data._id,
              hotel_id: hotelId,
            },
          });
        };

        return (
          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleButtonClick}
          >
            {isEdit ? "Edit" : "Upload"}
          </Button>
        );
      },
    },
    {
      field: "invoice_url",
      headerName: "Status",
      width: 200,

      cellRenderer: () => {
        return (
          <Tag color="orange" icon={<CloseOutlined />}>
            Pending
          </Tag>
        );
      },
    },

    {
      headerName: "Date of Invoice/Checkout Date",
      width: 200,

      field: "Date of Invoice/Checkout Date",
      filter: "agDateColumnFilter", // Date filter for date values
    },
    {
      headerName: "Booking ID/Invoice No",
      width: 200,
      cellRenderer: (params) => {
        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() =>
              handleCopyData(
                params?.data?.["Booking ID/Invoice No"] || params.value
              )
            }
          >
            <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />
            {params?.data?.["Booking ID/Invoice No"] || params.value}
          </div>
        );
      },
      field: "Booking ID/Invoice No",
      filter: "agTextColumnFilter", // Text filter for ticket/confirmation number
    },
    {
      headerName: "Client Name",
      field: "Client Name",
      width: 200,
      enableRowGroup: true,
      filter: "agTextColumnFilter", // Text filter for entity name
      cellRenderer: (params) => {
        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() =>
              handleCopyData(params?.data?.["Client Name"] || params.value)
            }
          >
            <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />
            {params?.data?.["Client Name"] || params.value}
          </div>
        );
      },
    },
    {
      headerName: "Passenger Name",
      width: 200,
      cellRenderer: (params) => {
        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() =>
              handleCopyData(params?.data?.["Passenger Name"] || params.value)
            }
          >
            <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />
            {params?.data?.["Passenger Name"] || params.value}
          </div>
        );
      },
      field: "Passenger name",
      filter: "agTextColumnFilter", // Text filter for passenger name
    },
    {
      headerName: "Hotel GSTIN",
      width: 200,

      field: "Hotel GSTIN",
      filter: "agTextColumnFilter", // Text filter for GSTIN
    },
    {
      headerName: "Hotel Location",
      width: 200,

      field: "Hotel Address",
      filter: "agTextColumnFilter", // Text filter for state (string)
    },
    {
      headerName: "Hotel Name",
      width: 200,

      field: "Hotel Name",
      filter: "agTextColumnFilter", // Text filter for hotel name
    },
    {
      headerName: "CGST",
      width: 200,

      field: "CGST",
      filter: "agNumberColumnFilter", // Number filter for numeric values
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["CGST"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
    },
    {
      headerName: "Customer GSTIN",
      width: 200,
      enableRowGroup: true,

      field: "Customer gstin",
      filter: "agTextColumnFilter", // Text filter for GSTIN (string)
      // cellRenderer: (params) => {
      //   return (
      //     <div
      //       style={{ width: "100%", height: "100%", cursor: "pointer" }}
      //       onClick={() =>
      //         handleCopyData(params?.data?.["Customer GSTIN"] || params.value)
      //       }
      //     >
      //       <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />
      //       {params?.data?.["Customer GSTIN"] || params.value}
      //     </div>
      //   );
      // },
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Customer GSTIN"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
    },
    {
      headerName: "Eligibility of E-Invoice",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Eligibility of E-Invoice"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Eligibility of einvoice.einvoiceEnable",
      filter: "agTextColumnFilter", // Text filter for eligibility status
    },
    {
      headerName: "E-Invoice Status",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["E-Invoice Status"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Eligibility of einvoice.einvoiceStatus",
      filter: "agTextColumnFilter", // Text filter for invoice status
    },
    {
      headerName: "GSTIN Address",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["GSTIN Address"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "GSTIN Address",
      filter: "agTextColumnFilter", // Text filter for address
    },

    {
      headerName: "IGST",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["IGST"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "IGST",
      filter: "agNumberColumnFilter", // Number filter for IGST (numeric)
    },
    {
      headerName: "Invoice Present",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Invoice Present"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Invoice_present",
      filter: "agSetColumnFilter", // Set filter for boolean values (true/false)
    },
    {
      headerName: "No of Nights",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["No of Nights"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "No of Nights",
      filter: "agNumberColumnFilter", // Number filter for numeric values
    },

    {
      headerName: "SGST",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["SGST"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "SGST",
      filter: "agNumberColumnFilter", // Number filter for SGST (numeric)
    },

    {
      headerName: "Total GST",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Total GST"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Total GST",
      filter: "agNumberColumnFilter", // Number filter for total GST (numeric)
    },
    {
      headerName: "Trip End Date",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Trip End Date"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Trip End Date",
      filter: "agDateColumnFilter", // Date filter for trip end date
    },
    {
      headerName: "Trip Start Date",
      width: 200,

      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Trip Start Date"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "Trip Start Date",
      filter: "agDateColumnFilter", // Date filter for trip start date
    },
    {
      headerName: "Base/Reimbursement",
      width: 200,
      cellRenderer: (params) => {
        return hotelInfo?.registered ? (
          params.data["Base/Reimbursement"]
        ) : (
          <div style={{ width: "100%", height: "100%", filter: "blur(5px)" }}>
            Please Register
          </div>
        );
      },
      field: "base/ reimbursement",
      filter: "agTextColumnFilter", // Text filter for base/reimbursement
    },
  ];

  useEffect(() => {
    fetchHotelInfo();
    // handleFetchHotelBookings();
  }, []);
  const fetchHotelInfo = async () => {
    setLoading(true);
    const response = await axios.get(API_ENDPOINT_HOTEL_INFO + hotelId);
    if (response.status) {
      setHotelInfo(response.data.data);
    }
    setLoading(false);
  };

  const handleFetchHotelBookings = async () => {
    const response = await axios.get(
      API_ENDPOINT_HOTEL_GET_BOOKING + hotelId + "/bookings"
    );
    if (response.status) {
      setBookingList(response.data.data);
    }
  };

  function getFileExtension(fileName) {
    // Split the file name by the dot character
    let parts = fileName.split(".");

    // Get the last part of the array which should be the file extension
    let extension = parts[parts.length - 1];

    return extension;
  }

  const handleFileUpload = async (file) => {
    setUploading(true);
    let formData = new FormData();
    let fileExtension = getFileExtension(file.name);
    formData.append("file", file);
    formData.append(
      "filename",
      `file_${generateRandomHash(32)}.${fileExtension}`
    );
    formData.append("path", "test_bulk_upload/");

    formData.append("bucket_name", "hotel-gst-bulk-upload-invoices");

    const response = await axios.post(
      API_ENDPOINT_STORAGE_S3_UPLOAD_OPEN,
      formData
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Thanks for uploading invoices",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not upload invoice for the booking",
      });
    }
    setShowModal(false);
    setUploading(false);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);

    // Set the server-side datasource here
    const datasource = {
      getRows: (params) => {
        const request = params.request;
        console.log("Payload:", request);

        fetch(API_ENDPOINT_HOTEL_GET_BOOKING + hotelId + "/bookings", {
          method: "POST",
          body: JSON.stringify({ ...request, hotelId: hotelId }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Data is:", data);
            checkForMissingGSTIN(data.data.rows);
            params.success({ rowData: data.data.rows });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            params.fail();
          });
      },
    };

    // Set the server-side datasource
    params.api.setGridOption("serverSideDatasource", datasource);
  };

  const checkForMissingGSTIN = (dataRows) => {
    let foundMissing = false;
    dataRows.forEach((item, index) => {
      if (
        !foundMissing &&
        (!item["Hotel GSTIN"] || item["Hotel GSTIN"].trim() === "")
      ) {
        console.log(`Entry ${index}: Hotel GSTIN is not present`);
        setMissingGSTINIndex(index);
        setShowGSTINModal(true);
        foundMissing = true;
        if (intervalRef.current) clearInterval(intervalRef.current); // Clear previous interval if any
      }
    });
  };

  const handleCloseGSTINModal = () => {
    setShowGSTINModal(false);
    if (intervalRef.current) clearInterval(intervalRef.current); // Clear any existing timer
    intervalRef.current = setTimeout(() => {
      setShowBottomRightCard(true);
    }, 5000); // Set timer to show card 5 seconds after the modal closes
  };

  const GSTIN_REGEX =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;

  const handleGSTINSubmit = async () => {
    const trimmedGstin = gstinInput.trim();

    // Validate GSTIN format
    if (!GSTIN_REGEX.test(trimmedGstin)) {
      message.error(
        "The GSTIN entered is not valid. Please check and try again."
      );
      return;
    }

    if (trimmedGstin !== "") {
      try {
        const response = await axios.post(
          "https://dev-backend.finkraftai.com/updategstin",
          {
            hotel_id: hotelId,
            gstin: trimmedGstin,
          }
        );

        if (response.status === 200) {
          message.success("GSTIN updated successfully!");
          setRowData((prevData) =>
            prevData.map((item) => ({ ...item, "Hotel GSTIN": trimmedGstin }))
          );
          window.location.reload();
          setShowGSTINModal(false);
          setShowBottomRightCard(false);
          setGstinInput("");
          if (intervalRef.current) clearInterval(intervalRef.current);
        } else {
          message.error("Failed to update GSTIN. Please try again.");
        }
      } catch (error) {
        console.error("Error updating GSTIN:", error);
        message.error("An error occurred while updating GSTIN.");
      }
    } else {
      message.error("GSTIN field cannot be empty.");
    }
  };

  useEffect(() => {
    if (!showBottomRightCard && missingGSTINIndex !== null) {
      intervalRef.current = setInterval(() => {
        setShowBottomRightCard(true);
      }, 10000);
    }

    return () => clearInterval(intervalRef.current);
  }, [showBottomRightCard, missingGSTINIndex]);

  function generateRandomHash(length = 16) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const debouncedSearch = debounce((value) => {
    if (gridApi) {
      gridApi.setFilterModel({
        "Passenger name": {
          filterType: "text",
          type: "contains",
          filter: value,
        },
        "Client Name": {
          filterType: "text",
          type: "contains",
          filter: value,
        },
        "Date of Invoice/Checkout Date": {
          filterType: "text",
          type: "contains",
          filter: value,
        },
      });
      gridApi.onFilterChanged();
    }
  }, 500); // 500ms delay

  const onSearchChange = useCallback(
    (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <div className="HotelBookingPage">
      <div className="HeaderContainer">
        <HeaderNew />
      </div>
      <div className="MainContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="HotelInfoContainer">
              <div className="Left">
                <div className="HotelInfo">
                  <img src={HotelIcon} alt="hotelIcon" />
                  <h2>{hotelInfo?.["Hotel Name"]}</h2>
                </div>

                <div className="HotelMetaInfoContainer">
                  <div className="MetaData">
                    <FileTextOutlined />
                    <p>{hotelInfo?.pending_invoice_count} Invoices</p>
                  </div>

                  <div className="MetaData" style={{ marginLeft: 24 }}>
                    <GlobalOutlined />
                    <p>{hotelInfo?.["Hotel Address"]}</p>
                  </div>
                </div>
              </div>
              <div className="Right">
                {/* <Button size="small">View</Button>
                <Button
                  icon={<UnlockOutlined />}
                  color="primary"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: 24 }}
                  onClick={() => setShowModal(true)}
                >
                  Unlock Analytics
                </Button> */}
                <Button
                  icon={<MailOutlined />}
                  type="primary"
                  size="small"
                  style={{ marginLeft: 24 }}
                  onClick={() => handleTriggerEmail(hotelInfo?.zoho_crm_id, 1)}
                >
                  Send Priority Mail One
                </Button>

                <Button
                  icon={<MailOutlined />}
                  type="primary"
                  size="small"
                  style={{ marginLeft: 24 }}
                  onClick={() => handleTriggerEmail(hotelInfo?.zoho_crm_id, 2)}
                >
                  Send Priority Mail 2
                </Button>
                <Button
                  icon={<CloudUploadOutlined />}
                  type="primary"
                  size="small"
                  style={{ marginLeft: 24 }}
                  onClick={() => setShowModal(true)}
                >
                  Invoice Bulk Upload
                </Button>
              </div>
            </div>
            <div className="TableContainer">
              <div className="SearchContainer">
                <Input
                  placeholder="Search booking (Invoice/Ticket number ,Entity Name or Passenger)"
                  style={{ width: 450 }}
                  onChange={onSearchChange}
                />
              </div>
              <div className="ag-theme-alpine">
                <AgGridReact
                  rowModelType="serverSide" // Important: Enables server-side row model
                  cacheBlockSize={100} // Optional: number of rows to load at once
                  paginationPageSize={100} // Optional: set page size
                  columnDefs={columnDefs}
                  onGridReady={onGridReady}
                  ref={gridRef}
                  enableFilter={true}
                  enablePivot={true}
                  enableSorting={true}
                  rowGroupPanelShow={"always"}
                  pivotPanelShow="always"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={showGSTINModal}
        onCancel={handleCloseGSTINModal}
        footer={null}
        title={null}
        centered
        className="style"
      >
        <div style={{ width: "100%" }}>
          {/* Header with full-width color and centered image */}
          <div
            style={{
              backgroundColor: "#DFF4FF",
              padding: "20px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure the background spans full width
              borderRadius: "12px",
            }}
          >
            <img
              src={table_gif} // Use your image here
              alt="GSTIN Illustration"
              style={{
                display: "block",
                maxHeight: "350px",
                maxWidth: "350px",
              }}
            />
          </div>

          {/* Main content of the modal */}
          <div
            style={{
              textAlign: "center",
              padding: "24px 25px", // Ensure no padding here
            }}
          >
            {/* Heading text */}
            <h2
              style={{ color: "#007CC2", fontSize: "32px", margin: "20px 0" }}
            >
              Enter your GSTIN Number
            </h2>

            {/* Explanation text */}
            <p
              style={{ color: "black", fontSize: "14px", marginBottom: "20px" }}
            >
              Why <b>GSTIN</b> is important: This point is to tell why it’s
              important and how it is helpful in the process.
            </p>

            {/* Container for Input and Button */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* Input field for GSTIN entry */}
              <Input
                placeholder="Enter GSTIN"
                value={gstinInput}
                onChange={(e) => setGstinInput(e.target.value)}
                style={{ flex: 8, marginBottom: "20px", padding: "7px" }} // 70% width
              />

              {/* Submit Button */}
              <Button
                type="primary"
                onClick={handleGSTINSubmit}
                style={{ flex: 2, marginBottom: "20px", padding: "9px" }} // 30% width
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {showBottomRightCard && (
        <Card style={styles.bottomRightCard}>
          <Button
            onClick={() => setShowBottomRightCard(false)}
            style={styles.closeButton}
          >
            ✕
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <img
              src={vector_gst}
              alt="GSTIN Notification"
              style={styles.imgStyle}
            />
            <span style={styles.textStyle}>Enter GSTIN</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input
              placeholder="Enter GSTIN"
              value={gstinInput}
              onChange={(e) => setGstinInput(e.target.value)}
              style={{ flex: 1, marginRight: "8px" }}
            />
            <Button type="primary" onClick={handleGSTINSubmit}>
              Submit
            </Button>
          </div>
        </Card>
      )}

      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={600}
        title="Upload Invoices"
      >
        <div style={{ height: 350 }}>
          {uploading ? (
            <Loader />
          ) : (
            <Uploader onSelect={handleFileUpload} multiple />
          )}
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
};
const styles = {
  upload: {
    color: "#007CC2",
    fontSize: "12px",
    display: "flex",
    padding: "6px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px solid #007CC2",
  },
  edit: {
    color: "#D98E30",
    fontSize: "12px",
    display: "flex",
    padding: "6px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px solid #D98E30",
  },
  uploadFrame: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "normal",
    gap: "8px",
    background: "transparent",
    border: "none",
    color: "#007CC2",
  },
  EditFrame: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "normal",
    gap: "8px",
    background: "transparent",
    border: "none",
    color: "#D98E30",
  },
  table: {
    width: "100%",
    height: "100vh",
    padding: "135px 70px 50px 70px",
    fontSize: "12px",
  },
  modalContent: {
    padding: "0", // Set padding to zero
    // You can add more styles to customize
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  bottomRightCard: {
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "400px",
    boxShadow: "0 -2px 8px rgba(0,0,0,0.1)",
    border: "1px solid #d9d9d9",
    borderRadius: "8px 8px 0 0",
    zIndex: 1000,
    padding: "16px",
    backgroundColor: "#fff",
    marginRight: "10px",
    marginBottom: "10px",
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    border: "none",
    background: "transparent",
    cursor: "pointer",
    fontSize: 16,
  },
  imgStyle: {
    width: "35px",
    height: "35px",
    marginRight: "8px",
  },
  textStyle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#999999",
  },
};

export default HotelBookingPageInternal;
