// import React, { useState, useRef, useEffect } from 'react';

// // Function to add highlight boxes
// const addHighlightBox = (boundingBox, scaleX, scaleY, containerScrollRef, margin = 20) => {
//   if (!boundingBox || boundingBox.length === 0) return null;
//   const [x1, y1, x2, y2, x3, y3, x4, y4] = boundingBox;
//   const left = Math.min(x1, x2, x3, x4) * scaleX;
//   const top = Math.min(y1, y2, y3, y4) * scaleY;
//   const width = (Math.max(x2, x3) - Math.min(x1, x4)) * scaleX;
//   const height = (Math.max(y3, y4) - Math.min(y1, y2)) * scaleY;

//   return (
//     <div
//       className="bounding-box"
//       style={{
//         position: 'absolute',
//         left: `${left}px`,
//         top: `${top}px`,
//         width: `${width}px`,
//         height: `${height}px`,
//         border: '2px solid red',
//         pointerEvents: 'none',
//         backgroundColor: 'rgba(255, 255, 0, 0.3)',
//         boxSizing: 'border-box',
//         zIndex: 1000,
//       }}
//       key={`highlight-${x1}-${y1}`}
//       ref={(el) => {
//         if (el && containerScrollRef.current) {
//           const container = containerScrollRef.current;
//           const elRect = el.getBoundingClientRect();
//           const containerRect = container.getBoundingClientRect();

//           // Calculate the scroll position to include a margin
//           if ((elRect.top - margin) < containerRect.top || (elRect.bottom + margin) > containerRect.bottom) {
//             container.scrollTo({
//               top: el.offsetTop - margin,
//               behavior: 'smooth',
//             });
//           }
//         }
//       }}
//     />
//   );
// };

// // Main Magnifier component
// const Magnifier = ({ base64Image, scale = 2, magnifierHeight = 150, magnifierWidth = 150, boundingBoxes }) => {
//   const [showMagnifier, setShowMagnifier] = useState(false);
//   const [magnifierStyle, setMagnifierStyle] = useState({});
//   const imgRef = useRef(null);
//   const containerScrollRef = useRef(null);
//   const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

//   useEffect(() => {
//     const updateImageDimensions = () => {
//       if (imgRef.current) {
//         setImageDimensions({
//           width: imgRef.current.clientWidth,
//           height: imgRef.current.clientHeight,
//         });
//       }
//     };

//     if (imgRef.current) {
//       imgRef.current.addEventListener('load', updateImageDimensions);
//       updateImageDimensions();
//     }
//     return () => {
//       if (imgRef.current) {
//         imgRef.current.removeEventListener('load', updateImageDimensions);
//       }
//     };
//   }, [base64Image]);

//   const handleMouseEnter = () => {
//     setShowMagnifier(true);
//   };

//   const handleMouseMove = (e) => {
//     const img = imgRef.current;
//     const { top, left, width, height } = img.getBoundingClientRect();

//     const x = e.clientX - left;
//     const y = e.clientY - top;

//     if (x > 0 && y > 0 && x < width && y < height) {
//       const backgroundX = (x / width * img.naturalWidth * scale - magnifierWidth / 2) * -1;
//       const backgroundY = (y / height * img.naturalHeight * scale - magnifierHeight / 2) * -1;

//       const magnifierX = e.clientX - left - magnifierWidth / 2;
//       const magnifierY = e.clientY - top + 10;

//       setMagnifierStyle({
//         left: magnifierX,
//         top: magnifierY,
//         backgroundPosition: `${backgroundX}px ${backgroundY}px`,
//         backgroundSize: `${img.naturalWidth * scale}px ${img.naturalHeight * scale}px`,
//       });
//     } else {
//       setShowMagnifier(false);
//     }
//   };

//   const handleMouseLeave = () => {
//     setShowMagnifier(false);
//   };

//   return (
//     <div
//       className="image-magnifier-container"
//       onMouseEnter={handleMouseEnter}
//       onMouseMove={handleMouseMove}
//       onMouseLeave={handleMouseLeave}
//       style={{ position: 'relative', height: '500px', overflow: 'auto' }}
//       ref={containerScrollRef}
//     >
//       {base64Image && (
//         <img
//           src={`data:image/png;base64,${base64Image}`}
//           ref={imgRef}
//           alt="Document"
//           style={{ objectFit: "contain", maxWidth: '100%', display: 'block' }}
//         />
//       )}
//       {showMagnifier && base64Image && (
//         <div
//           className="magnifier"
//           style={{
//             ...magnifierStyle,
//             position: 'absolute',
//             pointerEvents: 'none',
//             width: `${magnifierWidth}px`,
//             height: `${magnifierHeight}px`,
//             borderRadius: '50%',
//             backgroundImage: `url(data:image/png;base64,${base64Image})`,
//             backgroundRepeat: 'no-repeat',
//             border: '3px solid #000',
//             zIndex: 9999,
//           }}
//         />
//       )}
//       {boundingBoxes && Object.keys(boundingBoxes).map((key) => {
//         const box = boundingBoxes[key];
//         const scaleX = imageDimensions.width / (imgRef.current?.naturalWidth || 1);
//         const scaleY = imageDimensions.height / (imgRef.current?.naturalHeight || 1);
//         if (Array.isArray(box[0])) {
//           return box.map((nestedBox, index) =>
//             addHighlightBox(nestedBox, scaleX, scaleY, containerScrollRef, 40)
//           );
//         } else {
//           return addHighlightBox(box, scaleX, scaleY, containerScrollRef, 40);
//         }
//       })}
//     </div>
//   );
// };

// export default Magnifier;


import React, { useState, useRef, useEffect } from 'react';

// Function to add highlight boxes
const addHighlightBox = (boundingBox, scaleX, scaleY, containerScrollRef, onMouseEnterHandler, margin = 20) => {
  if (!boundingBox || boundingBox.length === 0) return null;
  const [x1, y1, x2, y2, x3, y3, x4, y4] = boundingBox;
  const left = Math.min(x1, x2, x3, x4) * scaleX;
  const top = Math.min(y1, y2, y3, y4) * scaleY;
  const width = (Math.max(x2, x3) - Math.min(x1, x4)) * scaleX;
  const height = (Math.max(y3, y4) - Math.min(y1, y2)) * scaleY;

  return (
    <div
      className="bounding-box"
      style={{
        position: 'absolute',
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
        border: '2px solid red',
        pointerEvents: 'auto',
        backgroundColor: 'rgba(255, 255, 0, 0.3)',
        boxSizing: 'border-box',
        zIndex: 1000,
      }}
      key={`highlight-${x1}-${y1}`}
      onMouseEnter={() => onMouseEnterHandler(boundingBox)}
      ref={(el) => {
        if (el && containerScrollRef.current) {
          const container = containerScrollRef.current;
          const elRect = el.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // Calculate the scroll position to include a margin
          if ((elRect.top - margin) < containerRect.top || (elRect.bottom + margin) > containerRect.bottom) {
            container.scrollTo({
              top: el.offsetTop - margin,
              behavior: 'smooth',
            });
          }
        }
      }}
    />
  );
};

// Function to display a magnified bounding box outside the image
const MagnifyOutsideImage = ({ boundingBox, imgRef, scaleFactor, style }) => {
  if (!boundingBox || !imgRef.current) return null;
  
  const [x1, y1, x2, y2] = boundingBox;
  const img = imgRef.current;

  const boxWidth = x2 - x1;
  const boxHeight = y2 - y1;
  const backgroundPositionX = -x1 * scaleFactor;
  const backgroundPositionY = -y1 * scaleFactor;

  return (
    <div
      className="magnified-bounding-box"
      style={{
        ...style,
        width: `${boxWidth * scaleFactor}px`,
        height: `${boxHeight * scaleFactor}px`,
        backgroundImage: `url(${img.src})`,
        backgroundPosition: `${backgroundPositionX}px ${backgroundPositionY}px`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${img.naturalWidth * scaleFactor}px ${img.naturalHeight * scaleFactor}px`,
        border: '2px solid blue',
        zIndex: 9999,
      }}
    />
  );
};

// Main Magnifier component
const Magnifier = ({ base64Image, scale = 2, magnifierHeight = 150, magnifierWidth = 150, boundingBoxes }) => {
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [magnifierStyle, setMagnifierStyle] = useState({});
  const [hoveredBoundingBox, setHoveredBoundingBox] = useState(null);

  const imgRef = useRef(null);
  const containerScrollRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateImageDimensions = () => {
      if (imgRef.current) {
        setImageDimensions({
          width: imgRef.current.clientWidth,
          height: imgRef.current.clientHeight,
        });
      }
    };

    if (imgRef.current) {
      imgRef.current.addEventListener('load', updateImageDimensions);
      updateImageDimensions();
    }
    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener('load', updateImageDimensions);
      }
    };
  }, [base64Image]);

  const handleMouseEnter = () => {
    setShowMagnifier(true);
  };

  const handleMouseMove = (e) => {
    const img = imgRef.current;
    const { top, left, width, height } = img.getBoundingClientRect();

    const x = e.clientX - left;
    const y = e.clientY - top;

    if (x > 0 && y > 0 && x < width && y < height) {
      const backgroundX = (x / width * img.naturalWidth * scale - magnifierWidth / 2) * -1;
      const backgroundY = (y / height * img.naturalHeight * scale - magnifierHeight / 2) * -1;

      const magnifierX = e.clientX - left - magnifierWidth / 2;
      const magnifierY = e.clientY - top + 10;

      setMagnifierStyle({
        left: magnifierX,
        top: magnifierY,
        backgroundPosition: `${backgroundX}px ${backgroundY}px`,
        backgroundSize: `${img.naturalWidth * scale}px ${img.naturalHeight * scale}px`,
      });
    } else {
      setShowMagnifier(false);
    }
  };

  const handleMouseLeave = () => {
    setShowMagnifier(false);
  };

  const handleBoundingBoxHover = (boundingBox) => {
    setHoveredBoundingBox(boundingBox);
  };

  return (
    <div
      className="image-magnifier-container"
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative', overflow: 'auto' }}
      ref={containerScrollRef}
    >
      {base64Image && (
        <img
          src={`data:image/png;base64,${base64Image}`}
          ref={imgRef}
          alt="Document"
          style={{ objectFit: "contain", maxWidth: '100%', display: 'block' }}
        />
      )}
      {showMagnifier && base64Image && (
        <div
          className="magnifier"
          style={{
            ...magnifierStyle,
            position: 'absolute',
            pointerEvents: 'none',
            width: `${magnifierWidth}px`,
            height: `${magnifierHeight}px`,
            borderRadius: '50%',
            backgroundImage: `url(data:image/png;base64,${base64Image})`,
            backgroundRepeat: 'no-repeat',
            border: '3px solid #000',
            zIndex: 9999,
          }}
        />
      )}
      {boundingBoxes && Object.keys(boundingBoxes).map((key) => {
        const box = boundingBoxes[key];
        const scaleX = imageDimensions.width / (imgRef.current?.naturalWidth || 1);
        const scaleY = imageDimensions.height / (imgRef.current?.naturalHeight || 1);
        if (Array.isArray(box[0])) {
          return box.map((nestedBox, index) =>
            addHighlightBox(nestedBox, scaleX, scaleY, containerScrollRef, handleBoundingBoxHover, 40)
          );
        } else {
          return addHighlightBox(box, scaleX, scaleY, containerScrollRef, handleBoundingBoxHover, 40);
        }
      })}
      {hoveredBoundingBox && (
        <MagnifyOutsideImage
          boundingBox={hoveredBoundingBox}
          imgRef={imgRef}
          scaleFactor={scale}
          style={{position: 'absolute', top: '10px', right: '-250px'}}
        />
      )}
    </div>
  );
};

export default Magnifier;