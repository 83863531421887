import React, { useState } from 'react';
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { Input, Button, Spin } from 'antd';

const { Search } = Input;

const SearchDropdown = () => {
  const options = ['Angel Broking', 'Atomberg', 'BAIN & COMPANY', 'Barco', 'ERNST & YOUNG ASSOCIATES LLP', 'ERNST & YOUNG SERVICES PRIVATE LIMITED', 'EY Restructuring LLP', 'Gallagher', 'Genpact Enterprise Risk Consulting', 'Genpact India Pvt. Ltd.', 'Getinge', 'Hersheys', 'LG Electronics', 'Marsh', 'NUVAMA', 'NetApp', 'One Assist', 'Piramal', 'Renew Power', 'Rolls Royce', 'SAINT - GOBAIN INDIA', 'Schenider', 'Skoda', 'Tata Capital', 'Tata Communications', 'Tata Digital', 'URBANCLAP TECHNOLOGIES INDIA', 'Wipro', 'Yatra', 'HUL'];

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showDropdown, setShowDropdown] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setShowDropdown(true);
    setFilteredOptions(
      options.filter(option =>
        option.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option);
    setShowDropdown(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    sendOptionToEndpoint(searchTerm);
  };

  const sendOptionToEndpoint = (option) => {
    fetch('https://dev-backend.finkraftai.com/client_based_filter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedOption: option }),
    })
      .then(response => response.json())
      .then(data => {
        const filteredData = data.map(item => {
          const { _id, hotel_id, source_id, ...rest } = item;
          return rest;
        });
        setRowData(filteredData);
        setDataLoaded(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const downloadCSV = () => {
    if (rowData.length === 0) {
      console.error("No data to download");
      return;
    }
    
    const headers = Object.keys(rowData[0]);
    const csv = [
      headers.join(','), // header row first
      ...rowData.map(row => headers.map(header => JSON.stringify(row[header] || '')).join(','))
    ].join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const columnDefs = [
    { headerName: "Booking ID", field: "Booking ID" },
    { headerName: "Booking ID/Invoice No", field: "Booking ID/Invoice No" },
    { headerName: "Client GSTIN", field: "Client GSTIN" },
    { headerName: "Client Name", field: "Client Name" },
    { headerName: "Dataset", field: "Dataset" },
    { headerName: "Date of Invoice/Checkout Date", field: "Date of Invoice/Checkout Date" },
    { headerName: "Hotel Address", field: "Hotel Address" },
    { headerName: "Hotel GSTIN", field: "Hotel GSTIN" },
    { headerName: "Hotel Name", field: "Hotel Name" },
    { headerName: "Hotel Names Count", field: "Hotel Names Count" },
    { headerName: "Invoice No", field: "Invoice No" },
    { headerName: "Total GST", field: "Total GST" },
  ];

  return (
    <div style={{margin:'30px'}}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", marginRight: 10 }}>
            <Search
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              placeholder="Search..."
              style={{ width: 200 }}
            />
            {showDropdown && (
              <ul style={{
                position: "absolute",
                zIndex: 1,
                border: '1px solid #ccc',
                backgroundColor: '#fff',
                listStyleType: 'none',
                padding: 0,
                margin: 0,
                width: 200,
                maxHeight: 180,
                overflowY: 'auto'
              }}>
                {filteredOptions.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    style={{ cursor: 'pointer', padding: '5px 10px', borderBottom: '1px solid #ccc'}}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <Button type="primary" onClick={downloadCSV}>
          Download CSV
        </Button>
      </div>
      {dataLoaded && (
        <div
          className="ag-theme-alpine"
          style={{
            height: 700,
            width: '100%',
            marginTop: 20
          }}
        >
          {loading ? (
            <Spin tip="Loading..." size="large">
              <div style={{ height: '400px', width: '100%' }} />
            </Spin>
          ) : (
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              pagination={true}
              paginationPageSize={20}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;