import React, { createContext, useState } from 'react';

// Create the context
export const GstinContext = createContext();

// Create a provider component
export const GstinProvider = ({ children }) => {
  const [gstin, setGstinForm] = useState('');

  return (
    <GstinContext.Provider value={{ gstin, setGstinForm }}>
      {children}
    </GstinContext.Provider>
  );
};
