import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "./HotelContactManagement.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "../../shared/Loader";
import moment from "moment";
import {
  MailOutlined,
  PhoneOutlined,
  EditOutlined,
  FileExclamationOutlined,
  PlusOutlined,
  CloseOutlined,
  CopyOutlined,
  ExportOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import { Input, Popconfirm, Segmented, Select, Switch } from "antd";
import { FormattedNumber } from "react-intl";

import { Badge, Button, Drawer, message, Modal, Tag, Tooltip } from "antd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Handlebars from "handlebars";

import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import HotelDataUpdate from "../../shared/HotelData";
import { param } from "jquery";
import SearchGSTIN from "./components/SearchGSTIN";
import HotelGSTINMapping from "./components/HotelGSTINMapping";
import {
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_HOTEL_CONTACT_UPDATE_META,
  API_ENDPOINT_HOTEL_LIST_NEW,
  API_ENDPOINT_HOTEL_TRIGGER_MAIL,
  API_ENDPOINT_HOTEL_UPDATE_META_INFO,
  API_ENDPOINT_HOTEL_VIEW_LIST,
} from "./hotelcontact.constants";
import AddColumnWidget from "./components/AddColumnWidget";

const colorOfEmailEvent = {
  processed: "green",
  delivered: "green",
  open: "green",
  click: "green",
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};

export default function HotelContactManagementClient(props) {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [searchQuery, setSearchQuery] = useState("");
  const [segmentValue, setSegmentValue] = useState("All");
  const [originalData, setOriginalData] = useState([]);

  const fetchHotelList = async () => {
    setLoading(true);
    const response = await axios.post(API_ENDPOINT_HOTEL_LIST_NEW);
    console.log("response statis", response.data);
    if (response.status) {
      setRowData(response.data.data);
      setOriginalData(response.data.data); 
    }
    setLoading(false);
  };

  const EVENT_ICON_MAP = {
    processed: (color) => (
      <CheckOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    delivered: (color) => (
      <DoneAllOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    open: (color) => (
      <DraftsOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    click: (color) => (
      <AdsClickOutlinedIcon
        style={{
          fontSize: 18,
          color: "#007cc2",
          cursor: "pointer",
        }}
      />
    ),
    acknowledge: (color) => (
      <AssignmentTurnedInIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
          marginRight: 28,
        }}
      />
    ),

    UNSUBSCRIBE: (color) => (
      <UnsubscribeOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    SPAMMED: (color) => (
      <PrivacyTipOutlinedIcon
        style={{
          fontSize: 18,
          color: color,
          cursor: "pointer",
        }}
      />
    ),
    bounce: (color) => (
      <CancelOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),

    dropped: (color) => (
      <MarkunreadMailboxOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),

    DEFFERED: (color) => (
      <ScheduleSendOutlinedIcon
        style={{
          fontSize: 18,
          color: "#ffa39e",
          cursor: "pointer",
        }}
      />
    ),
  };

  useEffect(() => {
    applySegmentFilter(segmentValue);
  }, [segmentValue, gridApi]);

  const applySegmentFilter = (value) => {
    if (gridApi) {
      let filterModel;
      if (value === "Mail Sent") {
        filterModel = {
          email_sent_status: {
            filterType: "text",
            type: "equals",
            filter: "Yes",
          },
        };
      } else if (value === "Mail Not Sent") {
        filterModel = {
          email_sent_status: {
            filterType: "text",
            type: "equals",
            filter: "No",
          },
        };
      } else {
        filterModel = null; // Clear filters when "All" is selected
      }
      gridApi.setFilterModel(filterModel);
      gridApi.onFilterChanged();
    }
  };

  const onSegmentChange = (value) => {
    setSegmentValue(value); // Update segment value
  };

  const fetchTemplateInfo = async () => {
    const response = await axios.get(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-ed0fbc920d5149569f317cb6ccbfacb2"
    );
    if (response) {
      setTemplateData(response.data.html_content);
    }
  };

  const handleRenderEmailEvents = (eventData) => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: 6,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip placement="bottom" title={eventData?.event}>
            {EVENT_ICON_MAP[eventData?.event] ? (
              <Tag icon={EVENT_ICON_MAP[eventData?.event]("blue")}>
                {eventData?.event}
              </Tag>
            ) : (
              <Tag color="red">No Event</Tag>
            )}
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleAddGSTIN = (data) => {
    setSelectedHotel(data);
    setOpenDrawer(true);
  };

  const gridOptions = {
    rowModelType: "serverSide",
    serverSideStoreType: "partial", // For pagination
    cacheBlockSize: 100, // Number of rows per block (pagination size)
    paginationPageSize: 100, // Page size
  };

  const handleTriggerEmail = async (hotelInfo) => {
    try {
      const response = await axios.post(
        API_ENDPOINT_HOTEL_TRIGGER_MAIL + hotelInfo._id,
        {
          body: hotelInfo,
        }
      );
      console.log(response);

      if (response.data.status) {
        const actualDataEvent = response.data.event; // Assume 'event' holds your actual event data from the API
        const actualTimestamp = response.data.timestamp || new Date(); // Use current time if not provided

        messageApi.success({
          type: "success",
          content: `Email Sent to hotel ${hotelInfo.name}`,
        });

        // Use the grid API to update the row
        if (gridApi) {
          gridApi.forEachNode((node) => {
            if (node.data._id === hotelInfo._id) {
              // Update the row node data using actual API data
              node.setData({
                ...node.data,
                last_email: { timestamp: actualTimestamp }, // Use actual timestamp
                last_email_event: { event: actualDataEvent || "Sent" }, // Update the email log with actual event
                email_sent_status: true, // Set email sent status to true
              });
            }
          });
        }
      } else {
        messageApi.error({
          type: "error",
          content: response.data.message || "Failed to send email",
        });
      }
    } catch (error) {
      messageApi.error({
        type: "error",
        content: "An error occurred while sending email",
      });
      console.error(error);
    }
  };

  const handleCopyData = (data) => {
    if (navigator.clipboard) {
      // Clipboard API supported
      navigator.clipboard
        .writeText(data)
        .then(() => {
          console.log("Text copied to clipboard");
          messageApi.success({
            type: "success",
            content: `${data} copied`,
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Clipboard API not supported, fallback to older method
      messageApi.open({
        type: "info",
        content: `Please copy manually something went wrong`,
      });
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);

    if (gridApi) {
      gridApi.setFilterModel({
        zoho_crm_id: {
          filterType: "text",
          type: "contains",
          filter: value,
        },
        address: {
          filterType: "text",
          type: "contains",
          filter: value,
        },
      });
      gridApi.onFilterChanged();
    }
  }, 500);

  // const onSearchChange = useCallback(
  //   (event) => {
  //     const value = event.target.value;
  //     debouncedSearch(value);
  //   },
  //   [debouncedSearch]
  // );

  const onSearchChange = useCallback(
    (event) => {
      const value = event.target.value.toLowerCase();
      setSearchQuery(value);

      if (originalData) {
        const filteredData = originalData.filter((item) => {
          return (
            (item['Hotel Name'] && item['Hotel Name'].toLowerCase().includes(value)) ||
            (item['Hotel Address'] && item['Hotel Address'].toLowerCase().includes(value)) ||
            (item['mapped_gstin'] && item['mapped_gstin'].toLowerCase().includes(value))
          );
        });

        setRowData(filteredData);
      }
    },
    [originalData]
  );

  const handleMarkResponseType = async (params, responseType) => {
    const response = await axios.post(
      API_ENDPOINT_HOTEL_UPDATE_META_INFO + params.data?._id,
      {
        responseType: responseType,
      }
    );
    if (response.status) {
      gridRef.current &&
        gridRef.current.api.applyTransaction({
          update: [
            {
              _id: params.data?._id,
              responseType: responseType,
            },
          ],
        });

      messageApi.success({
        type: "success",
        content: "Hotel response marked as positive",
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Hotel response marked as negative",
      });
    }
  };

  const handleUpdateCollectionRemark = async (hotelId, metaData) => {
    const response = await axios.post(API_ENDPOINT_HOTEL_CONTACT_UPDATE_META, {
      metaData: metaData,
      hotelId: hotelId,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Hotel remarks updated",
      });
    } else {
      messageApi.open({
        type: "error",
        content: `Could not update hotel remarks`,
      });
    }
  };

  const columnDefs = [
    // {
    //   field: "sno",
    //   headerName: "SR No",
    //   cellRenderer: (params) => {
    //     return parseInt(params.node.id) + 1;
    //   },
    // },
    // {
    //   field: "email_sent_status",
    //   headerName: "Email Sent Status",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     values: ["Yes", "No"],
    //   },
    //   valueGetter: (params) => {
    //     return params.data?.last_email == undefined ? "No" : "Yes";
    //   },
    //   cellRenderer: (params) => {
    //     return params.value === "Yes" ? (
    //       <Tag color="green">Yes</Tag>
    //     ) : (
    //       <Tag color="red">No</Tag>
    //     );
    //   },
    // },
    // {
    //   field: "email_logs",
    //   headerName: "Email Logs",
    //   width: 300,
    //   cellRenderer: (params) => {
    //     const { last_email_event } = params.data || {};
    //     const dynamicEvent =
    //       last_email_event?.event?.toLowerCase() || "no event";

    //     // Display the event with initial capitalization if not 'Sent'
    //     const displayEvent =
    //       dynamicEvent === "sent"
    //         ? "Sent"
    //         : dynamicEvent.charAt(0).toUpperCase() + dynamicEvent.slice(1);

    //     // Set the box background and border color
    //     const borderAndIconColor = "#007cc2"; // Desired color for both icon and border

    //     // Use the EVENT_ICON_MAP to fetch and style the icon
    //     const IconElement = EVENT_ICON_MAP[dynamicEvent];
    //     const icon = IconElement ? (
    //       <IconElement style={{ color: borderAndIconColor }} />
    //     ) : null;

    //     // Define the text style specifically for black text
    //     const textStyle = { color: "#333333" };

    //     // Render the tag and contents
    //     return (
    //       <Tag
    //         color="transparent"
    //         style={{
    //           borderColor: borderAndIconColor,
    //           borderWidth: 1,
    //           borderStyle: "solid",
    //         }}
    //       >
    //         <span style={{ display: "flex", alignItems: "center" }}>
    //           {icon}
    //           <span style={{ marginLeft: 5, ...textStyle }}>
    //             {displayEvent}
    //           </span>
    //         </span>
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   field: "last_mail_sent",
    //   headerName: "Last Email Sent",
    //   width: 300,
    //   valueGetter: (params) => {
    //     return (
    //       params.data.last_email &&
    //       params.data.last_email.timestamp &&
    //       moment(params.data.last_email.timestamp).format("DD-MM-YYYY HH:mm") // Use HH:mm for 24-hour format
    //     );
    //   },
    // },
    // {
    //   field: "template",
    //   headerName: "Template",
    //   width: 300,
    //   cellRenderer: (params) => {
    //     return (
    //       <Tag
    //         icon={<FileExclamationOutlined />}
    //         color="blue"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           setSelectedBooking(params.data);
    //           setShowModal("TEMPLATE_VIEW");
    //         }}
    //       >
    //         Followup Template
    //       </Tag>
    //     );
    //   },
    // },
    {
      field: "contact_details",
      headerName: "Contact",
      width: 100,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["All", "Have Contacts", "Does Not Have Contact"],
      },
      cellRenderer: (params) => {
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setSelectedBooking(params.data);
              setShowModal("ADD_HOTEL");
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                backgroundColor: "black",
                alignItems: "center",
                display: "flex",
                color: "white",
                fontSize: 12,
                justifyContent: "center",
                marginRight: 4,
              }}
            >
              {params.data?.contact_details?.length}
            </div>
            <EditOutlined style={{ cursor: "pointer" }} />
          </div>
        );
      },
      valueGetter: (params) => {
        return params.data?.contact_details?.length || 0;
      },
    },
    {
      field: "invoice_provider",
      headerName: "Has Invoice Provider",
      width: 200,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.invoice_provider : params.node.key,
      cellRenderer: (params) => {
        const dataValue = params.data
          ? params.data?.invoice_provider
          : params.node.key;
        return (
          <Tag
            style={{ cursor: "pointer" }}
            color={dataValue ? "green" : "red"}
          >
            {dataValue ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    {
      field: "total_row_count",
      headerName: "Pending Invoices",
      width: 100,
      columnGroupShow: "open",

      cellRenderer: (params) => {
        const handleRedirectHotelBooking = () => {
          const hotelId = params.data?._id;
          const url = `https://hotelsgst.com//bookingsnew/${hotelId}`;
          window.open(url, "_blank"); // Open the URL in a new tab
        };
        return (
          <Tag
            style={{ cursor: "pointer" }}
            color="orange"
            onClick={handleRedirectHotelBooking}
          >
            {params.data?.total_row_count}
          </Tag>
        );
      },
    },
    {
      field: "mapped_gstin",
      headerName: "Hotel GSTIN",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      width: 200,
      valueGetter: (params) =>
        params.data ? params.data?.mapped_gstin : params.node.key,
      cellRenderer: (params) => {
        const isGrouped = params.column.isRowGroupActive();

        return isGrouped ? (
          "-"
        ) : params.data?.mapped_gstin ? (
          params.data?.mapped_gstin
        ) : (
          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddGSTIN(params?.data)}
          >
            Add GSTIN
          </Button>
        );
      },
    },
    {
      field: "hotel_name_crm",
      headerName: "Hotel Name CRM",
      width: 300,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        params.data ? params.data?.hotel_name_crm : params.node.key,
      cellRenderer: (params) => {
        const handleHotelNameClick = () => {
          if (!params.data?.zoho_crm_id) return;
          const hotelId = params.data?._id;
          const url = `https://crm.zoho.in/crm/org60016690758/tab/Accounts/${params.data?.zoho_crm_id}`;
          window.open(url, "_blank"); // Open the URL in a new tab
        };

        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={handleHotelNameClick}
          >
            {params.data?.zoho_crm_id ? (
              <ExportOutlined style={{ marginRight: 4, fontSize: 20 }} />
            ) : null}
            {params.data?.["Booking ID/Invoice No"] || params?.value}
          </div>
        );
      },
    },
    {
      field: "Hotel Name",
      headerName: "Hotel Name",
      width: 300,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        params.data ? params.data?.["Hotel Name"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() =>
              handleCopyData(
                params.data ? params.data?.["Hotel Name"] : params.node.key
              )
            }
          >
            <CopyOutlined style={{ marginRight: 4, fontSize: 20 }} />

            {params.data ? params.data?.["Hotel Name"] : params.node.key}
          </div>
        );
      },
    },
    {
      field: "email_logs",
      headerName: "Email Logs",
      width: 200,
      cellRenderer: (params) => {
        return params.data?.last_email ? (
          <Button
            size="small"
            type="primary"
            icon={<ExportOutlined style={{ marginRight: 4, fontSize: 20 }} />}
            onClick={() => {
              const url = `https://hotelsgst.com/email_analytics?hotelId=${params.data?._id}`;
              window.open(url, "_blank");
            }}
          >
            Check Email Logs
          </Button>
        ) : null;
      },
    },
    {
      field: "Hotel Address",
      headerName: "Hotel Address",
      width: 200,
      filter: "agTextColumnFilter",
    },

    {
      field: "Source",
      headerName: "Source",
      filter: "agTextColumnFilter",
    },

    {
      field: "ITC_per_invoice",
      headerName: "ITC per invoice",
      width: 100,
      cellClass: "right-align",
      valueGetter: (params) =>
        params.data ? params.data?.["ITC_per_invoice"] : params.node.key,
      cellRenderer: (params) => (
        <FormattedNumber
          value={params.data?.["ITC_per_invoice"] || 0}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      field: "Total GST",
      headerName: "Total GST",
      width: 100,
      cellClass: "right-align",
      valueGetter: (params) =>
        params.data ? params.data?.["Total GST"] : params.node.key,
      cellRenderer: (params) => (
        <FormattedNumber
          value={params.data?.["Total GST"] || 0}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      field: "Zone",
      headerName: "Zone",
      width: 150,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "Follow_Up_required",
      headerName: "Follow Up Required",
      width: 100,
      valueGetter: (params) =>
        params.data ? params.data?.["Follow_Up_required"] : params.node.key,
      cellRenderer: (params) => (
        <Tag
          color={params.data?.Follow_Up_required === "Yes" ? "red" : "green"}
        >
          {params.data?.Follow_Up_required || "No"}
        </Tag>
      ),
    },
    {
      field: "call_made",
      headerName: "1st Call",
      width: 100,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["call_made"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div style={{ width: "100%" }}>
            <Switch
              checked={
                params.data ? params.data?.["call_made"] : params.node.key
              }
              size="small"
              onChange={() => {
                let newValue = params.data?.call_made ? false : true;
                params.node.setDataValue("call_made", newValue); // Update the cell data in AG Grid

                handleUpdateCollectionRemark(params.data?._id, {
                  call_made: newValue,
                });
              }}
            />
          </div>
        );
      },
    },

    {
      field: "manual_email_required",
      headerName: "Email Sent",
      width: 100,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["manual_email_required"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div style={{ width: "100%" }}>
            <Switch
              checked={
                params.data
                  ? params.data?.["manual_email_required"]
                  : params.node.key
              }
              size="small"
              onChange={() => {
                let newValue = params.data?.manual_email_required
                  ? false
                  : true;
                params.node.setDataValue("manual_email_required", newValue); // Update the cell data in AG Grid

                handleUpdateCollectionRemark(params.data?._id, {
                  manual_email_required: newValue,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      field: "call_required",
      headerName: "Followup Call",
      enableRowGroup: true,
      width: 100,
      valueGetter: (params) =>
        params.data ? params.data?.["call_required"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div style={{ width: "100%" }}>
            <Switch
              checked={
                params.data ? params.data?.["call_required"] : params.node.key
              }
              size="small"
              onChange={() => {
                let newValue = params.data?.call_required ? false : true;
                params.node.setDataValue("call_required", newValue); // Update the cell data in AG Grid

                handleUpdateCollectionRemark(params.data?._id, {
                  call_required: newValue,
                });
              }}
            />
          </div>
        );
      },
    },

    {
      field: "call_remarks",
      headerName: "Call  Remark",
      width: 300,
      editable: true,
    },

    {
      field: "hotel_visit_required",
      headerName: "Hotel Visit Required",
      width: 100,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["hotel_visit_required"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div style={{ width: "100%" }}>
            <Switch
              checked={
                params.data
                  ? params.data?.["hotel_visit_required"]
                  : params.node.key
              }
              size="small"
              onChange={() => {
                let newValue = params.data?.hotel_visit_required ? false : true;
                params.node.setDataValue("hotel_visit_required", newValue); // Update the cell data in AG Grid

                handleUpdateCollectionRemark(params.data?._id, {
                  hotel_visit_required: newValue,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      field: "hotel_visit_done",
      headerName: "Hotel Visit Done",
      width: 100,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["hotel_visit_done"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <div style={{ width: "100%" }}>
            <Switch
              checked={
                params.data
                  ? params.data?.["hotel_visit_done"]
                  : params.node.key
              }
              size="small"
              onChange={() => {
                let newValue = params.data?.hotel_visit_done ? false : true;
                params.node.setDataValue("hotel_visit_done", newValue); // Update the cell data in AG Grid

                handleUpdateCollectionRemark(params.data?._id, {
                  hotel_visit_done: newValue,
                });
              }}
            />
          </div>
        );
      },
    },
    {
      field: "hotel_visit_remark",
      headerName: "Hotel Visit Remark",
      width: 200,
      filter: "agTextColumnFilter",
      editable: true,
    },
    {
      field: "general_remarks",
      headerName: "General Hotel Remark",
      width: 200,
      editable: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "followup_by",
      headerName: "Assign Call Follow Up",
      width: 200,
      enableRowGroup: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        params.data ? params.data?.["followup_by"] : params.node.key,
      cellRenderer: (params) => {
        return (
          <Select
            placeholder="Select Assignee"
            style={{ width: 120 }}
            defaultValue={
              params.data ? params.data?.["followup_by"] : params.node.key
            }
            onChange={(selectedValue) => {
              params.node.setDataValue("followup_by", selectedValue); // Update the cell data in AG Grid

              handleUpdateCollectionRemark(params.data?._id, {
                followup_by: selectedValue,
              });
            }}
            size="small"
            options={[
              { value: "Meenal", label: "Meenal" },
              { value: "Pooja N", label: "Pooja N" },
              { value: "Dipali H", label: "Dipali H" },
              { value: "Mayuri", label: "Mayuri" },
              { value: "Chandana", label: "Chandana" },
              { value: "Rakesh", label: "Rakesh" },
              { value: "Indrani", label: "Indrani" },
              { value: "Monica", label: "Monica" },
              { value: "Sushmita", label: "Sushmita" },
              { value: "Gurpreet", label: "Gurpreet" },
              { value: "Pooja S", label: "Pooja S" },
            ]}
          />
        );
      },
    },
    {
      field: "email_followup_by",
      headerName: "Assign Email Follow Up",
      filter: "agTextColumnFilter",
      width: 200,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["email_followup_by"] : params.node.key,
      cellRenderer: (params) => {
        console.log("params:", params);
        return (
          <Select
            placeholder={params.value}
            style={{ width: 120 }}
            defaultValue={
              params.data ? params.data?.["email_followup_by"] : params.node.key
            }
            onChange={(selectedValue) => {
              params.node.setDataValue("email_followup_by", selectedValue); // Update the cell data in AG Grid

              handleUpdateCollectionRemark(params.data?._id, {
                email_followup_by: selectedValue,
              });
            }}
            size="small"
            options={[
              { value: "Gurpreet", label: "Gurpreet" },
              { value: "Indrani", label: "Indrani" },
              { value: "Rakesh", label: "Rakesh" },
              { value: "Monica", label: "Monica" },
              { value: "Sushmita", label: "Sushmita" },
            ]}
          />
        );
      },
    },
    {
      field: "responseType",
      headerName: "Hotel Response",
      width: 200,
      enableRowGroup: true,
      valueGetter: (params) =>
        params.data ? params.data?.["responseType"] : params.node.key,
      cellRenderer: (params) => {
        let dataValue = params.data
          ? params.data?.["responseType"]
          : params.node.key;
        return (
          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            {dataValue === "POSITIVE" ? (
              <Tag color="green" icon={<LikeOutlined />}>
                POSITIVE
              </Tag>
            ) : dataValue === "NEGATIVE" ? (
              <Tag color="red" icon={<DislikeOutlined />}>
                NEGATIVE
              </Tag>
            ) : (
              <>
                <Button
                  size="small"
                  type="primary"
                  icon={<LikeOutlined />}
                  onClick={() => handleMarkResponseType(params, "POSITIVE")}
                >
                  Positive
                </Button>

                <Button
                  size="small"
                  type="primary"
                  danger
                  style={{ marginLeft: 12 }}
                  icon={<DislikeOutlined />}
                  onClick={() => handleMarkResponseType(params, "NEGATIVE")}
                >
                  Negative
                </Button>
              </>
            )}
          </div>
        );
      },
    },

    {
      headerName: "Counts",
      children: [
        {
          field: "expense_row_count",
          headerName: "Expense Row Count",
          width: 100,
          columnGroupShow: ["open", "close"],
        },

        {
          field: "booking_row_count",
          headerName: "Bookings Row Count",
          width: 100,
          columnGroupShow: "open",
        },
        {
          field: "twob_row_count",
          headerName: "2B Row Count",
          width: 100,
          columnGroupShow: "open",
        },
      ],
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   cellRenderer: (params) => {
    //     return params.data?.contact_details?.length > 0 ? (
    //       <Button
    //         size="small"
    //         type="primary"
    //         onClick={() => handleTriggerEmail(params.data)}
    //       >
    //         Send Email
    //       </Button>
    //     ) : null;
    //   },
    // },
  ];

  useEffect(() => {
    fetchHotelList();
    fetchTemplateInfo();
  }, []);

  const datasource = {
    getRows: function (params) {
      // Use the fetched data to update the grid
      fetch(API_ENDPOINT_HOTEL_VIEW_LIST, {
        method: "POST",
        body: JSON.stringify({
          ...params.request,
          ...props.serverPayload,
        }),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((httpResponse) => httpResponse.json())
        .then((response) => {
          params.success({
            rowData: response.data.rows,
            rowCount: response.data.lastRow,
            ...(response.data.pivotResultFields &&
            response.data.pivotResultFields.length > 0
              ? { pivotResultFields: response.data.pivotResultFields }
              : {}),
          });

          // handleColumnRowGroupChanged(params);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    //setColumnApi(params.columnApi);

    // Set the server-side datasource here
    const datasource = {
      getRows: (params) => {
        const request = params.request;
        console.log("Payload:", request);

        fetch(API_ENDPOINT_HOTEL_VIEW_LIST, {
          method: "POST",
          body: JSON.stringify(request),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Data is:", data);
            params.success({ rowData: data.data });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            params.fail();
          });
      },
    };

    // Set the server-side datasource
    params.api.setGridOption("serverSideDatasource", datasource);
  };

  const renderTemplate = (htmlContent, data = {}) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler({ hotel_name: data?.name });

    return renderedContent;
  };

  const gridRef = useRef();

  const handleAddColumn = () => {
    console.log("adding column");
  };

  const handleCellValueChange = (params) => {
    const updatedValue = params.newValue;
    const rowId = params.data._id;
    const updatedField = params.colDef.field;
    let updatableObject = {};
    updatableObject[updatedField] = updatedValue;
    handleUpdateCollectionRemark(rowId, updatableObject);

    // You can further handle the updated value here, like making an API call
  };

  return (
    <div className="HotelContactManagement">
      <div className="MainContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div
              className="SearchContainer"
              style={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "space-between",
                margin: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Search by Hotel Name or Address"
                  style={{ width: 350 }}
                  onChange={onSearchChange}
                />
                {/* <div>
              <AddColumnWidget />
            </div> */}
              </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: "90%" }}>
              <AgGridReact
                // defaultColDef={{ resizable: true, flex: 1 }}
                columnDefs={columnDefs}
                rowData={rowData}
                onGridReady={onGridReady}
                ref={gridRef}
                rowGroupPanelShow={"always"}
                pagination={true}
                onCellValueChanged={handleCellValueChange}
                getRowId={(params) => params.data._id}
                sideBar={{
                  toolPanels: [
                    {
                      id: "columns",
                      labelDefault: "Columns",
                      labelKey: "columns",
                      iconKey: "columns",
                      toolPanel: "agColumnsToolPanel",
                    },
                    {
                      id: "filters",
                      labelDefault: "Filters",
                      labelKey: "filters",
                      iconKey: "filter",
                      toolPanel: "agFiltersToolPanel",
                    },
                  ],
                }}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        title={"Update Hotel Contact"}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
        width={showModal === "TEMPLATE_VIEW" ? 800 : 600}
      >
        {showModal === "TEMPLATE_VIEW" ? (
          <div
            style={{ height: "70vh", overflowY: "scroll" }}
            dangerouslySetInnerHTML={{
              __html: renderTemplate(templateData || "", selectedBooking),
            }}
          ></div>
        ) : (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              fetchHotelList();
              // fetchBookingData();
            }}
          />
        )}
      </Modal>

      <Drawer
        title="Map Your GSTIN"
        placement={"right"}
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        key={"right"}
      >
        <HotelGSTINMapping
          hotelData={selectedHotel}
          onSuccess={() => {
            setOpenDrawer(false);
          }}
        />
      </Drawer>
      {contextHolder}
    </div>
  );
}
