import { Button, Checkbox, Input, message, Select } from "antd";
import { PushpinOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT_HOTEL_INFO_REGISTER_STAFF } from "../../landing.page.constant";
export default function PersonalInfoForm(props) {
  const [designation, setDesignation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleStaffRegistration = async () => {
    const payload = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      designation: designation,
      phone: phone,
    };
    if (!email || !firstName || !lastName) {
      messageApi.error({
        type: "error",
        content: "Please provide important information",
      });
      return;
    }

    const response = await axios.post(
      `${API_ENDPOINT_HOTEL_INFO_REGISTER_STAFF}${props?.hotelInfo?.insertedId}/personal`,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "🎉 Awesome, Personal information saved successfully!",
      });
      props.onSuccess && props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Something went wrong while regisration",
      });
    }
    props.onSuccess && props.onSuccess();
  };
  return (
    <div className="HotelInfoForm">
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>Designation</small>
        <br />
        <Input
          placeholder="Designation"
          onChange={(e) => setDesignation(e.target.value)}
        />
      </div>
      <div
        className="FormGroup"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 18,
        }}
      >
        <div className="FormGroupHalf" style={{ width: "49%" }}>
          <small style={{ fontSize: 14, color: "#555555" }}>First Name</small>
          <br />
          <Input
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="FormGroupHalf" style={{ width: "49%" }}>
          <small style={{ fontSize: 14, color: "#555555" }}>Last Name</small>
          <br />
          <Input
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>Email *</small>
        <Input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="FormGroup" style={{ marginBottom: 18 }}>
        <small style={{ fontSize: 14, color: "#555555" }}>Contact No </small>
        <Input
          placeholder="Contact No"
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={handleStaffRegistration}
        >
          Proceed
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
