import React, { useRef, useEffect } from 'react';
import './meter.css'

const SimpleGauge = ({ percent = 80, width = '250px', value  }) => {
    const containerRef = useRef(null);
    const baseRef = useRef(null);
    const progressRefs = useRef([]);
    const pointBorderRef = useRef(null);  // Add a reference for the border circle

    useEffect(() => {
        const container = containerRef.current;
        const widthValue = container.clientWidth;

        const strokeWidth = widthValue / 20;
        const height = (widthValue / 3) + (strokeWidth / 10);
        const ratio = (widthValue - strokeWidth) / 3.5
        const offset = Math.PI * ratio;

        let validPercent = percent >= 0 ? (percent <= 100 ? percent : 100) : 0;
        const segment = offset / 4; // Divide the gauge into 4 segments

        const colors = ['#007CC2', '#3399D6', '#66B3E0', '#99CDED']; // Red, Orange, Yellow, Green

        container.style.height = `${height}px`;

        const baseCircle = baseRef.current;
        baseCircle.style.width = '100%';
        baseCircle.style.height = '100%';
        baseCircle.style.fill = 'none';
        baseCircle.style.stroke = '#E5E5E5';
        baseCircle.style.strokeLinecap = 'round';
        baseCircle.setAttribute('cx', ratio);
        baseCircle.setAttribute('cy', ratio);
        baseCircle.setAttribute('r', ratio);
        baseCircle.style.strokeDashoffset = offset;
        baseCircle.style.strokeDasharray = offset;
        baseCircle.style.strokeWidth = strokeWidth.toString();
        baseCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;

        colors.forEach((color, idx) => {
            const progressCircle = progressRefs.current[idx];
            progressCircle.style.width = '100%';
            progressCircle.style.height = '100%';
            progressCircle.style.fill = 'none';
            progressCircle.style.stroke = color;
            progressCircle.style.strokeLinecap = 'round';
            progressCircle.setAttribute('cx', ratio);
            progressCircle.setAttribute('cy', ratio);
            progressCircle.setAttribute('r', ratio);
            progressCircle.style.strokeDashoffset = offset - (segment * idx);
            progressCircle.style.strokeWidth = strokeWidth.toString();
            progressCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
            progressCircle.style.strokeDasharray = `${segment} ${offset * 2 - segment}`;
        });

        const pointBorderCircle = pointBorderRef.current; // Reference to the border circle
        pointBorderCircle.style.width = '100%';
        pointBorderCircle.style.height = '100%';
        pointBorderCircle.style.fill = 'none';
        pointBorderCircle.style.stroke = 'white'; // Set the color of the border to white
        pointBorderCircle.style.strokeLinecap = 'round';
        pointBorderCircle.setAttribute('cx', ratio);
        pointBorderCircle.setAttribute('cy', ratio);
        pointBorderCircle.setAttribute('r', ratio);
        pointBorderCircle.style.strokeWidth = (strokeWidth + 12).toString(); // Increase the size for a larger border
        pointBorderCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
        pointBorderCircle.style.strokeDasharray = `1 ${(offset * 2) - 1}`;
        pointBorderCircle.style.strokeDashoffset = offset - (validPercent * offset / 100);

        const pointCircle = progressRefs.current[4];
        pointCircle.style.width = '100%';
        pointCircle.style.height = '100%';
        pointCircle.style.fill = 'none'; // Ensure the point circle is transparent
        pointCircle.style.strokeLinecap = 'round';
        pointCircle.setAttribute('cx', ratio);
        pointCircle.setAttribute('cy', ratio);
        pointCircle.setAttribute('r', ratio);
        pointCircle.style.strokeWidth = (strokeWidth + 6).toString(); // Increase the size for the point circle

        // Determine the color based on the percent value
        let pointColor;
        if (percent < 25) {
            pointColor = '#007CC2';
        } else if (percent < 50) {
            pointColor = '#3399D6'; 
        } else if (percent < 75) {
            pointColor = '#66B3E0'; 
        } else {
            pointColor = '#99CDED'; 
        }

        pointCircle.style.stroke = pointColor; // Set the color of the point circle based on the percent value
        pointCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
        pointCircle.style.strokeDasharray = `1 ${(offset * 2) - 1}`;
        pointCircle.style.strokeDashoffset = offset - (validPercent * offset / 100);

        const textElement = containerRef.current.querySelector('.number');
        textElement.style.fontSize = `${height / 3}px`;
        textElement.style.fontWeight = 'bold';
        textElement.style.marginTop = `-${height / 2}px`;
        textElement.style.color = 'black';
        textElement.style.display = 'grid';
        textElement.style.justifyContent = 'center';
    }, [percent]);

    return (
        <div style={{ position: 'relative'}} ref={containerRef} className='meterdiv'>
            <svg className="gauge-svg">
                <circle ref={baseRef}></circle>
                {[...Array(4)].map((_, i) => (
                    <circle ref={el => progressRefs.current[i] = el} key={i}></circle>
                ))}
                <circle ref={pointBorderRef}></circle> {/* Border circle for the point indicator */}
                <circle ref={el => progressRefs.current[4] = el}></circle> {/* Point indicator */}
            </svg>
            <div className="number" style={{ textAlign: 'center', fontSize: '20px',position: 'absolute', top: '8px', left: '29%',}}>{percent}</div>
            <div style={{ position: 'absolute', top: '8px', left: '35%', transform: 'translateX(-50%)', fontSize: '14px' }}>
                GST Score
            </div>
        </div>

    );
};

export default SimpleGauge;
