import { Button, Checkbox, Form, Input, message, Tag } from "antd";
import EmailUpdateForm from "../EmailUpdate";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import { PlusOutlined } from "@ant-design/icons";
import { API_ENDPOINT_HOTEL_DETAILS_UPDATE } from "../../scenes/HotelContactManagement/hotelcontact.constants";
import { API_ENDPOINT_HOTEL_CONTACT_CONFIRMATION_FOLLOWUP } from "../../scenes/HotelContactManagementClient/hotelcontact.constants";

export default function HotelDataUpdate(props) {
  const [hotelName, setHotelName] = useState("");
  const gridRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [primaryRow, setPrimaryRow] = useState(null); // Track primary row

  useEffect(() => {
    if (props.bookingData) {
      setHotelName(props.bookingData?.name);
      setRowData(props.bookingData?.contact_details);
    }
  }, [props.bookingData]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onCellValueChanged = (params) => {
    if (params.colDef.field === "email" && !validateEmail(params.newValue)) {
      messageApi.error({
        type: "error",
        content: "Please enter valid email",
      });
      params.node.setDataValue("email", params.oldValue); // Revert to old value
    }
  };

  const updateHotelContactInfo = async () => {
    const allData = [];
    gridRef.current.api.forEachNode((node, index) => {
      // Assign a temporary id based on row index
      allData.push({ ...node.data, tempId: index });
    });

    console.log("all data payload is:", allData);

    const payload = {
      contacts: allData,
    };

    try {
      const response = await axios.post(
        API_ENDPOINT_HOTEL_DETAILS_UPDATE + props.bookingData._id,
        payload
      );

      messageApi.success({
        type: "success",
        content: "Hotel details updated",
      });
      props.onSuccess();

      // setRowData(response.data.data);
    } catch (error) {
      console.error("Error fetching hotels:", error);
      messageApi.error({
        type: "error",
        content: "Could not updated hotel detail",
      });
    }
  };

  const addNewRow = () => {
    console.log("adding new row");
    const newRow = {
      email: "",
      phone: "",
      role: "Receptionist",
      contact_person: "",
      primary: false,
      invoice_provider: false,
    };
    setRowData([...rowData, newRow]);
  };
  const onPrimarySelect = (params) => {
    // Uncheck the previous primary row if it exists
    const updatedRows = rowData.map((row) => {
      if (row === primaryRow) {
        return { ...row, primary: false };
      }
      return row;
    });

    // Set the new primary row
    const newPrimaryRow = { ...params.data, primary: true };
    updatedRows[params.rowIndex] = newPrimaryRow;

    setRowData(updatedRows);
    setPrimaryRow(newPrimaryRow);
  };

  const handleCheckboxChange = (params) => {
    const rowIndex = params.node.id;
    // Update the rowData state based on the row ID
    setRowData((prevRowData) => {
      const updatedData = [...prevRowData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        invoice_provider: !params.data.invoice_provider,
      };
      return updatedData;
    });
  };

  const handleSendConfirmationMail = async (email, hotelName, hotelId) => {
    const response = await axios.post(
      API_ENDPOINT_HOTEL_CONTACT_CONFIRMATION_FOLLOWUP,
      {
        email: email,
        hotel_name: hotelName,
        id: hotelId,
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Confirmation Email has been sent",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not sent confirmation mail",
      });
    }
  };
  return (
    <div className="HotelUpdateForm" style={{ marginTop: 32 }}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
          hotel_name: hotelName,
        }}
        fields={[
          {
            name: ["hotel_name"],

            value: hotelName,
          },
        ]}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="hotel_name"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            placeholder="Hotel Name"
            defaultValue={hotelName}
            onChange={(e) => setHotelName(e.target.value)}
            disabled
          />
        </Form.Item>

        <div style={{ marginTop: 24, marginBottom: 24 }}>
          {/* <span style={{ fontSize: 12, marginBottom: 3 }}>Phone</span>
          <EmailUpdateForm
            emailData={hotelNumbers || []}
            onChange={(data) => setHotelNumbers(data)}
            placeholder="Add Phone"
            type="PHONE"
          /> */}

          <div className="ag-theme-alpine" style={{ height: 300 }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={[
                {
                  field: "contact_person",
                  headerName: "Name",
                  editable: true,
                },
                { field: "email", headerName: "Email", editable: true },
                { field: "phone", headerName: "Phone", editable: true },
                { field: "role", headerName: "Role", editable: true },
                {
                  field: "department",
                  headerName: "Department",
                  editable: true,
                },
                {
                  field: "remarks",
                  headerName: "Remarks",
                  editable: true,
                },
                {
                  field: "invoice_provider",
                  headerName: "Invoice Provider",
                  editable: true,
                  cellRenderer: (params) => {
                    return (
                      <Checkbox
                        checked={params.data?.invoice_provider}
                        onChange={() => handleCheckboxChange(params)}
                      />
                    );
                  },
                },
                {
                  field: "mail_confirmed",
                  headerName: "Mail Confirmed",
                  editable: true,

                  cellRenderer: (params) => {
                    return params.data?.mail_confirmed ? (
                      <Tag color="green">Yes</Tag>
                    ) : (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() =>
                          handleSendConfirmationMail(
                            params.data?.email,
                            props.bookingData?.["Hotel Name"],
                            props.bookingData?._id
                          )
                        }
                      >
                        Send Confirmation Email
                      </Button>
                    );
                  },
                },
              ]}
              rowGroupPanelShow="always"
              groupDisplayType="multipleColumns"
              ref={gridRef}
              //   onGridReady={onGridReady}
              rowSelection="single"
              animateRows={true}
              onCellValueChanged={onCellValueChanged} // Hook for value changes
            />
            <Tag
              style={{ marginTop: 6, cursor: "pointer" }}
              color="blue"
              icon={<PlusOutlined />}
              onClick={addNewRow}
            >
              Add Row
            </Tag>
          </div>
        </div>

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={updateHotelContactInfo}
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </div>
  );
}
