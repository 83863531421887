import { Button, Typography } from "antd";
import CheckIcon from "../../../../src/assets/check.png";
import { useNavigate } from "react-router-dom";
export default function HotelVerifySuccess(props) {
  const navigate = useNavigate();
  return (
    <div
      className="HotelInfoForm"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={CheckIcon} style={{ height: 88, width: 88 }} alt="CheckIcon" />
      <Typography
        style={{ marginTop: 24, color: "black", fontWeight: 600, fontSize: 26 }}
      >
        {" "}
        Great job 🎉
      </Typography>
      <Typography style={{ color: "black", fontWeight: 600, fontSize: 24 }}>
        Your account is verified
      </Typography>

      <Typography
        style={{
          marginTop: 6,
          fontWeight: 600,
          fontSize: 12,
          textAlign: "center",
        }}
      >
        Once verified, you'll have access to more of your data. Click 'Continue'
        to proceed.
      </Typography>

      <Button
        type="primary"
        style={{ marginTop: 24 }}
        onClick={() => navigate("/bookings/" + props.hotelInfo?.insertedId)}
      >
        Close
      </Button>
    </div>
  );
}
