import "./HotelGSTINMapping.scss";
import { Button, message } from "antd";
import SearchGSTIN from "../SearchGSTIN";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PlaceIcon from "@mui/icons-material/Place";
import axios from "axios";
import { useState } from "react";
import { API_ENDPOINT_HOTEL_UPDATE_INFO } from "../../hotelcontact.constants";
export default function HotelGSTINMapping(props) {
  const [gstin, setGSTIN] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleUpdateGSTIN = async () => {
    const response = await axios.post(
      API_ENDPOINT_HOTEL_UPDATE_INFO + props.hotelData._id,
      { gstin: gstin }
    );
    messageApi.success({
      type: "success",
      content: "Hotel GSTIN updated",
    });
    props.onSuccess && props.onSuccess();
  };
  return (
    <div className="HotelGSTINMapping">
      <div className="HotelInfoContainer">
        <ApartmentIcon style={{ fontSize: 18 }} />
        <div className="HotelName">{props.hotelData?.name}</div>
      </div>
      <div className="HotelInfoContainer">
        <PlaceIcon style={{ fontSize: 18 }} />
        <div className="HotelName">{props.hotelData?.address}</div>
      </div>

      <div className="SearchContainer">
        <small>Search GSTIN</small>
        <SearchGSTIN onSelect={(data) => setGSTIN(data)} />
      </div>

      <Button
        style={{ width: "100%" }}
        type="primary"
        onClick={handleUpdateGSTIN}
      >
        Save GSTIN
      </Button>
      {contextHolder}
    </div>
  );
}
