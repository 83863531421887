import { Typography, Upload } from "antd";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "./Uploader.scss";
const { Dragger } = Upload;

export default function Uploader(props) {
  const beforeUploadHandler = () => {
    return false; // Allow default action
  };

  const draggerProps = {
    name: "file",
    multiple: true,
    beforeUpload: beforeUploadHandler,
    onChange(info) {
      const { status } = info.file;
      console.log("new file", info.file);
      props.onSelect && props.onSelect(info.file, info.fileList);
    },
    onDrop(e) {},
    showUploadList: false,
  };

  return (
    <Dragger
      {...draggerProps}
      style={{
        backgroundColor: props.colored ? "#0A83940A" : "white",
        padding: "38px 0",
        cursor: "pointer",
        height: 260,
      }}
    >
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlinedIcon
          style={{
            fontSize: 34,
            color: "#007cc2",
          }}
          fontSize="large"
        />
      </p>
      <Typography
        style={{
          textAlign: "center",
          color: "gray",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Drag and Drop or choose multiple pdf/images to upload
      </Typography>
      <p className="ant-upload-hint" style={{ color: "black", fontSize: 12 }}>
        {props.fileType || "Allowed Files PDF , Image, Zip"}
      </p>
    </Dragger>
  );
}
