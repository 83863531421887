// Sidecontent.js
import React from 'react';
import image34 from '../assets/image 34.png';
import image36 from '../assets/image 36.png';
import image37 from '../assets/image 37.png';

const Sidecontent = () => {
  return (
    <div style={styles.innerContainer}>
      <div style={styles.topImageContainer}>
        <img src={image34} alt="Main Image" style={styles.mainImage} />
      </div>
      <div style={styles.bottomImagesContainer}>
        <img src={image36} alt="Sub Image 1" style={styles.subImage} />
        <img src={image37} alt="Sub Image 2" style={styles.subImage} />
      </div>
    </div>
  );
};

const styles = {
  innerContainer: {
    flex: 1.1,
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    fontFamily: "Noto Sans",
  },
  topImageContainer: {
    width: '95%',
    height: '95%',
  },
  mainImage: {
    width: '110%',
    height: '90%',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  bottomImagesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '110%',
    height: '110%',
    gap: '1px',
    marginTop:'-40px'
    // marginTop: '20px', // Create space between the main image and the bottom images
  },
  subImage: {
    width: '90%',
    height: '90%',
    objectFit: 'cover',
    borderRadius: '10px',
  },
};

export default Sidecontent;