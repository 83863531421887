import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NavBar from "./LandingPage/NavBar";
import CheckScorePage from "./LandingPage/CheckScorePage";
import SignUp from "./RegistrationPage/SignUp";
import Verify from "./RegistrationPage/Verify";
import { FormDataProvider } from "./FormData/FormData";
import { GstinProvider } from "./FormData/GstinProvider";
import LogIn from "./LoginPage/LogIn";
import LoginVerify from "./LoginPage/LoginVerify";
import BookingDetailsTable from "./HotelTableData/BookingDetailsTable";
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import HotelDetails from "./RegistrationPage/HotelDetailsPage";
import ClientSection from "./clientsection/ClientSection";
import HotelContactManagement from "./scenes/HotelContactManagement";
import FinalLandingPage from "./NewResponsive_LandingPage/LandingPage";
import HotelBookingPage from "./HotelBookingPage";
import HotjarTracking from "./hotjartrackingsystem/HotjarTracking";
import "./agTable.scss";
import LandingPage from "./FinalLandingPage/LandingPage";
import HOTELFORM from "./Downloaddata/Downloaddata";
import ClientBasedFilter from "./Client_based_filter/ClientFilter";
import InternalData from "./InternalData";
import InternalEmailAnalytics from "./scenes/InternalEmailAnalytics";
import HotelContactManagementClient from "./scenes/HotelContactManagementClient";
import HotelBookingPageInternal from "./HotelBookingPageInternal";
import TriggerHotelSingleEmail from "./scenes/TriggerHotelSingleEmail";

const AppContent = () => {
  const location = useLocation();
  const hideNavbarPaths = [
    "/",
    "/verify",
    "/signup",
    "/contactinfo",
    "/confirminfo",
    "/accessdashboard",
    "/backaccountinfo",
    "/signin",
    "/loginverify",
    "/confirminfo2",
    "/hoteldetails",
    "/clients",
    "/hotel_bookings",
    "/bookings",
  ];

  return (
    <div>
      {/* {!hideNavbarPaths.includes(location.pathname) && <NavBar />} */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/bookings/:hotelId" element={<HotelBookingPage />} />
        <Route
          path="/bookingsnew/:hotelId"
          element={<HotelBookingPageInternal />}
        />

        <Route path="/email_analytics" element={<InternalEmailAnalytics />} />
        <Route
          path="/trigger_email/:crmId"
          element={<TriggerHotelSingleEmail />}
        />

        <Route path="/check-score" element={<CheckScorePage />} />
        <Route path="/hoteldetails" element={<HotelDetails />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify" element={<Verify />} />
        {/* <Route path="/contactinfo" element={<ContactInfo />} />
        <Route path="/confirminfo" element={<ConfirmYourInformation/>} />
        <Route path="/confirminfo2" element={<ConfirmYourInformation2/>} />
        <Route path="/backaccountinfo" element={<BankInfo/>}/>
        <Route path="/accessdashboard" element={<AccessDashboard/>}/> */}
        {/* <Route path="/new" element={<New/>}/> */}
        <Route path="/signin" element={<LogIn />} />
        <Route path="/loginverify" element={<LoginVerify />} />
        <Route path="/bookingTable" element={<BookingDetailsTable />} />
        <Route path="/invoiceform" element={<InvoiceForm />} />
        <Route path="/clients" element={<ClientSection />} />
        <Route
          path="/hotel_bookings"
          element={<HotelContactManagementClient />}
        />
        <Route
          path="/hotel_bookingsnew"
          element={<HotelContactManagementClient />}
        />
        <Route path="/downloaddata" element={<HOTELFORM />} />
        <Route path="/client_filter" element={<ClientBasedFilter />} />
        <Route path="/interal_data/:hotelId" element={<InternalData />} />
      </Routes>
    </div>
  );
};

const App = () => (
  <FormDataProvider>
    <GstinProvider>
      <Router>
        <HotjarTracking />
        <AppContent />
      </Router>
    </GstinProvider>
  </FormDataProvider>
);

export default App;
