import "./HeaderNew.scss";
import AppLogo from "../../../src/assets/hotellogo.png";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Typography } from "antd";
export default function HeaderNew(props) {
  return (
    <div className="HeaderNew">
      <div className="HeaderLeft">
        <img src={AppLogo} alt="ImgScreen" />
      </div>

      <div className="HeaderRight">
        {/* <Avatar size={44} icon={<UserOutlined />} /> */}
      </div>
    </div>
  );
}
